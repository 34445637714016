// vuex.js store for Sketchurizer


//// IMPORTS
import { resizeImage } from '@/utils/image' 


// WINDOW CONSTANTS
const WINDOW_LIBRARY = 'library'
const WINDOW_INPUT = 'input'
const WINDOW_PREVIEW = 'preview'
const WINDOW_MODEL = 'model'
const WINDOW_DEFAULT = WINDOW_INPUT


// WIDGET CONSTANTS
const WIDGET_SKETCH = 'sketch'
const WIDGET_UPLOAD = 'upload'
const WIDGET_CAMERA = 'camera'
const WIDGET_DEFAULT = WIDGET_SKETCH


// INPUT CONSTANTS
const INPUT_STRENGTH_DEFAULT = 0.5


// PROMPT CONSTANTS
const PROMPT_POSITIVE_DEFAULT = 'plain background, long shot, single object' 
const PROMPT_NEGATIVE_DEFAULT = '' 


// SORTING CONSTANTS
const SORT_TYPE_DATE = 'date'
const SORT_TYPE_RATING = 'rating'
const SORT_ORDER_ASCENDING = 'ascending'
const SORT_ORDER_DESCENDING = 'descending'


//// VUEX STORE
export const sketchurizer = {
    namespaced: true,
    state: {
        activeInput: {
            raw: null,          // sketch output
            strokes: 0,  
            background: null,   // sketch input (e.g., upload output)
            upload: null,       // upload input
            asset: null,
            assetIgnore: false,
            assetOutdated: false
        },
        activePreviewAssets: null,
        activeModelAsset: null,

        activeWindow: WINDOW_DEFAULT,
        activeWidget: WIDGET_DEFAULT,

        inputStrength: INPUT_STRENGTH_DEFAULT,
        promptPositive: '',
        promptNegative: '',

        hints: {},
    },
    mutations: {
        RESET(state) {
            state.activeInput.raw = null
            state.activeInput.strokes = 0
            state.activeInput.background = null
            state.activeInput.upload = null
            state.activeInput.asset = null
            state.activeInput.assetIgnore = false
            state.activeInput.assetOutdated = false
            state.activePreviewAssets = null
            state.activeModelAsset = null

            state.inputStrength = INPUT_STRENGTH_DEFAULT
            state.promptPositive = ''
            state.promptNegative = ''
        },

        SET_ACTIVE_INPUT_RAW(state, raw) {
            state.activeInput.raw = raw

            if (state.activeInput.assetIgnore) {
                state.activeInput.assetIgnore = false
            } else {
                state.activeInput.assetOutdated = true
            }
        },
        SET_ACTIVE_INPUT_STROKES(state, strokes) {
            state.activeInput.strokes = strokes
        },
        SET_ACTIVE_INPUT_BACKGROUND(state, background) {
            state.activeInput.background = background
        },
        SET_ACTIVE_INPUT_UPLOAD(state, upload) {
            state.activeInput.upload = upload
        },
        SET_ACTIVE_INPUT_ASSET(state, asset) {
            const data = asset.files['default'].data // TODO base
            state.activeInput.background = data
            state.activeInput.asset = asset
            state.activeInput.assetIgnore = true
            state.activeInput.assetOutdated = false
        },
        SET_ACTIVE_PREVIEW_ASSETS(state, assets) {
            state.activePreviewAssets = assets
        },
        SET_ACTIVE_MODEL_ASSET(state, asset) {
            state.activeModelAsset = asset
        },

        SET_ACTIVE_WINDOW(state, window) {
            state.activeWindow = window
        },
        SET_ACTIVE_WIDGET(state, widget) {
            state.activeWidget = widget
        },

        SET_INPUT_STRENGTH(state, strength) {
            state.inputStrength = strength
        },
        SET_PROMPT_POSITIVE(state, prompt) {
            state.promptPositive = prompt
        },
        SET_PROMPT_NEGATIVE(state, prompt) {
            state.promptNegative = prompt
        },

        SET_HINT(state, { hint, value }) {
            state.hints = {
              ...state.hints,
              [hint]: value
            }
        },
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowLibrary: () => WINDOW_LIBRARY,
        keyWindowInput: () => WINDOW_INPUT,
        keyWindowPreview: () => WINDOW_PREVIEW,
        keyWindowModel: () => WINDOW_MODEL,

        keyWidgetSketch: () => WIDGET_SKETCH,
        keyWidgetUpload: () => WIDGET_UPLOAD,
        keyWidgetCamera: () => WIDGET_CAMERA,

        keyInputStrengthDefault: () => INPUT_STRENGTH_DEFAULT,

        keyPromptPositiveDefault: () => PROMPT_POSITIVE_DEFAULT,
        keyPromptNegativeDefault: () => PROMPT_NEGATIVE_DEFAULT,

        keySortTypeDate: () => SORT_TYPE_DATE,
        keySortTypeRating: () => SORT_TYPE_RATING,

        keySortOrderAscending: () => SORT_ORDER_ASCENDING,
        keySortOrderDescending: () => SORT_ORDER_DESCENDING,

        getActiveInputRaw: (state) => state.activeInput.raw,
        getActiveInputStrokes: (state) => state.activeInput.strokes,
        getActiveInputBackground: (state) => state.activeInput.background,
        getActiveInputUpload: (state) => state.activeInput.upload,
        getActiveInputAsset: (state) => state.activeInput.asset,
        getActivePreviewAssets: (state) => state.activePreviewAssets,
        getActiveModelAsset: (state) => state.activeModelAsset,

        getAllInputAssets: (state, getters, rootState, rootGetters) => {
            return rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketchurizer'],
                rootGetters['api/keyAssetTypeImage']
            )
        },
        getAllPreviewAssets: (state, getters, rootState, rootGetters) => {
            return rootGetters['api/getFilteredAssets']( 
                rootGetters['app/keyAppSketchurizer'],
                rootGetters['api/keyAssetTypeLayer']
            )
        },
        getAllModelAssets: (state, getters, rootState, rootGetters) => {
            return rootGetters['api/getFilteredAssets']( 
                rootGetters['app/keyAppSketchurizer'],
                rootGetters['api/keyAssetTypeModel']
            )
        },

        getAssetCategory: (state, getters, rootState, rootGetters) => (asset) => {
            if (rootGetters['api/isAssetTypeImage'](asset)) return 'input'
            if (rootGetters['api/isAssetTypeLayer'](asset)) return 'preview'
            return asset.type
        },

        getActiveWindow: state => state.activeWindow,
        getActiveWidget: state => state.activeWidget,

        getInputStrength: state => state.inputStrength,
        getPromptPositive: state => state.promptPositive,
        getPromptNegative: state => state.promptNegative,

        getHint: (state) => (hint) => {
            return true //TODO temporaly disabled: state.hints[hint] || false
        },

        hasActiveInputAsset: state => state.activeInput.asset !== null,
        hasActivePreviewAssets: state => state.activePreviewAssets !== null,
        hasActiveModelAsset: state => state.activeModelAsset !== null,

        isActiveInputEmpty: state => {
            if (state.activeInput.raw === null) return true
            if (state.activeInput.background !== null) return false
            if (state.activeInput.strokes !== null && state.activeInput.strokes > 0) return false
            return true
        },
        isActiveInputAssetOutdated: state => state.activeInput.assetOutdated,

        isWindowDefault: state => state.activeWindow === WINDOW_DEFAULT,
        isWindowLibrary: state => state.activeWindow === WINDOW_LIBRARY,
        isWindowInput: state => state.activeWindow === WINDOW_INPUT,
        isWindowPreview: state => state.activeWindow === WINDOW_PREVIEW,
        isWindowModel: state => state.activeWindow === WINDOW_MODEL,

        isWidgetDefault: state => state.activeWidget === WIDGET_DEFAULT,
        isWidgetSketch: state => state.activeWidget === WIDGET_SKETCH,
        isWidgetUpload: state => state.activeWidget === WIDGET_UPLOAD,
        isWidgetCamera: state => state.activeWidget === WIDGET_CAMERA
    },
    actions: {

        reset({ commit }) {
            commit('RESET')
        },

        // open an asset (input, preview, or model)
        async openAsset({ dispatch, rootGetters }, {
            asset,
            show = true
        }) 
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            try
            {
                // reset vuex store 
                await dispatch('reset')

                // if input asset
                if (rootGetters['api/isAssetTypeImage'](asset))
                {
                    return dispatch('openAssetAsInput', { asset, show })
                }

                // if preview asset
                else if (rootGetters['api/isAssetTypeLayer'](asset))
                {
                    return dispatch('openAssetAsPreview', { asset, show })
                }

                // if model asset
                else if (rootGetters['api/isAssetTypeModel'](asset))
                {
                    return dispatch('openAssetAsModel', { asset, show })
                }

                else 
                {
                    throw new Error('Cannot open unsupported asset type.')
                }
            }
            catch(error)
            {
                throw error
            }
        },

        // open asset as input
        async openAssetAsInput({ dispatch, rootGetters }, {
            asset,
            show = true
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeImage'](asset) && 
                !rootGetters['api/isAssetTypeLayer'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // call route to retrieve asset file (with default key 'default')
                const response = await dispatch('api/assetsAssetFileKeyGet', { 
                    asset 
                }, { root: true })

                // after the file has been retreived, set the asset as active input
                dispatch('setActiveInputAsset', asset)

                // set prompt 
                if (asset.additional && asset.additional.positive)
                    dispatch('setPromptPositive', asset.additional.positive)
                if (asset.additional && asset.additional.negative)
                    dispatch('setPromptNegative', asset.additional.negative)

                // set guidance strength
                if (asset.additional && asset.additional.guidance)
                    dispatch('setInputStrength', asset.additional.guidance)

                // set window and widget to sketch input
                if (show) {
                    dispatch('setActiveWindowToInput')
                    dispatch('setActiveWidgetToSketch')
                }

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // open asset as preview
        async openAssetAsPreview({ dispatch, rootGetters }, {
            asset,
            show = true
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeImage'](asset) && 
                !rootGetters['api/isAssetTypeLayer'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // call route to retrieve asset file (with default key 'default')
                const response = await dispatch('api/assetsAssetFileKeyGet', { 
                    asset
                }, { root: true })

                // after the file has been retreived, set the asset as active preview
                dispatch('setActivePreviewAssets', [ asset ])

                // set prompt 
                if (asset.additional && asset.additional.positive)
                    dispatch('setPromptPositive', asset.additional.positive)
                if (asset.additional && asset.additional.negative)
                    dispatch('setPromptNegative', asset.additional.negative)

                // set guidance strength
                if (asset.additional && asset.additional.guidance)
                    dispatch('setInputStrength', asset.additional.guidance)

                // has parent?
                if (asset.parent) 
                {
                    // get parent asset (input)
                    const parentAsset = rootGetters['api/getAsset'](asset.parent)

                    // parent retrieved successfully?
                    if (parentAsset) await dispatch('openAssetAsInput', { asset: parentAsset, show })
                }

                // set window to preview
                if (show) {
                    dispatch('setActiveWindowToPreview')
                }

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // open asset as model
        async openAssetAsModel({ dispatch, rootGetters }, {
            asset,
            show = true
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to open.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeModel'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // call route to retrieve asset file (with default key 'default')
                const response = await dispatch('api/assetsAssetFileKeyGet', { 
                    asset 
                }, { root: true })

                // after the file has been retreived, set the asset as active model
                dispatch('setActiveModelAsset', asset)

                // has parent?
                if (asset.parent)
                {
                    // get parent asset (preview)
                    const parentAsset = rootGetters['api/getAsset'](asset.parent)

                    // parent retrieved successfully?
                    if (parentAsset) await dispatch('openAssetAsPreview', { asset: parentAsset, show })
                }

                // set window to model
                if (show) {
                    dispatch('setActiveWindowToModel') 
                }               

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // rate asset
        async rateAsset({ dispatch }, {
            assetId,
            rating
        })
        {
            // ensure assetId parameter is provided
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an assetId to rate.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // call route to rate asset
                const response = await dispatch('api/assetsAssetAdditionalPut', {
                    assetId,
                    additional: {
                        rating
                    }
                }, { root: true })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Asset Rating", 
                    description: "User has rated the asset: " + assetId
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log bug
                dispatch('app/logBug', { 
                    name: "Sketchurizer Asset Rating",
                    description: "Rating update failed for asset: " + assetId
                }, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Asset rating failed to update.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // edit asset
        async editAsset({ dispatch, getters, rootGetters }, {
            asset
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to edit.")
            }

            // ensure asset type is supported for input
            if (!rootGetters['api/isAssetTypeImage'](asset) && 
                !rootGetters['api/isAssetTypeLayer'](asset)) {
                throw new Error("Cannot open unsupported asset type.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })
                dispatch('app/setLoadingText', 'preparing sketch', { root: true })

                // reset vuex store 
                await dispatch('reset')
    
                // open asset as input
                const response = await dispatch('openAssetAsInput', { asset })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // log event
                dispatch('app/logEvent', { 
                    name: "Sketchurizer Asset Edit", 
                    description: "User has started to edit the asset: " + asset.id
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Opening the asset for editing has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // download asset
        async downloadAsset({ dispatch }, {
            asset,
            key = 'default',
            format = null
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to download.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // call route to download asset
                const response = await dispatch('api/assetsAssetDownloadHelper', {
                    asset,
                    key,
                    format
                }, { root: true })

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Downloading the asset was successful.'
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                 dispatch('app/showToast', { 
                    text: 'Downloading the asset has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // delete asset 
        async deleteAsset({ commit, dispatch, getters }, {
            asset,
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to delete.")
            }

            try
            {
                // activate loading overlay
                dispatch('app/activateLoading', null, { root: true })

                // call route to delete asset
                const response = await dispatch('api/assetsAssetDelete', {
                    assetId: asset.id
                }, { root: true })

                // get previews
                let previews = getters.getActivePreviewAssets

                // delete if preview asset and in previewAssets
                if (previews) 
                {
                    previews = previews.filter(preview => preview.id !== asset.id)
                    commit('SET_ACTIVE_PREVIEW_ASSETS', previews)
                }

                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                dispatch('app/showToast', { 
                    text: 'Deleting the asset was successful.'
                }, { root: true })

                return response
            }
            catch(error)
            {
                // deactivate loading overlay
                dispatch('app/deactivateLoading', null, { root: true })

                // provide user feedback
                 dispatch('app/showToast', { 
                    text: 'Deleting the asset has failed.', 
                    warning: true
                }, { root: true })

                throw error
            }
        },

        // delete asset flow
        async deleteAssetFlow({ dispatch, rootGetters }, {
            asset
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to delete.")
            }

            try
            {
                // pointer to current asset (for recursive delete)
                let currentAsset = asset

                // continue while currentAsset contains an asset
                while (currentAsset !== null && currentAsset !== undefined)
                {
                    // get parent asset
                    const parent = rootGetters['api/getAsset'](currentAsset.parent)

                    // get children (that depend on this asset)
                    const children = rootGetters['api/getChildAssets'](currentAsset.id)

                    // only delete if it does not have children
                    if (children == null || children.length <= 0)
                    {
                        await dispatch('deleteAsset', { asset: currentAsset })
                    }

                    // set asset for next recursion
                    currentAsset = parent
                }
            }
            catch(error)
            {
                throw error
            }
        },

        // create and set input asset (from raw input)
        async setInputAssetFromRaw({ commit, dispatch, getters, rootGetters }, {
            additional = null   
        })
        {
            try
            {
                // check if a new asset should be created
                if (getters.isActiveInputAssetOutdated || getters.getActiveInputAsset === null)
                {
                    // get raw input
                    const inputRaw = getters.getActiveInputRaw

                    // specify attributes
                    const type = rootGetters['api/keyAssetTypeImage']
        
                    // create thumbnail
                    const resolution = rootGetters['app/keyResolutionThumbnailDefault']
                    const thumbnail = await resizeImage(
                        inputRaw, 
                        resolution,
                        resolution
                    )

                    // call route to create asset
                    const response = await dispatch('api/assetsPost', {
                        type,
                        file: inputRaw,
                        thumbnail,
                        additional
                    }, { root: true })

                    // set input asset
                    commit('SET_ACTIVE_INPUT_ASSET', response)

                    return response
                }
                else
                {
                    return getters.getActiveInputAsset
                }
            }
            catch(error)
            {
                throw error
            }
        },

        // generate preview from prompt
        async generatePreviewFromPrompt({ dispatch }, {
            positive = '',
            negative = '',
            resolution = 1024, 
            seeds = [ -1 ],
            additional = null,
        })
        {
            // call route to generate layer from prompt
            return dispatch('api/layersFromPromptGeneratePost', {
                positive,
                negative,
                resolution,
                seeds,
                additional
            }, { root: true })
        },

        // generate preview from guidance
        async generatePreviewFromGuidance({ dispatch }, {
            canny = 0,
            depth = 0,
            positive = '',
            negative = '',
            resolution = 1024,
            seeds = [ -1 ],
            additional = null
        })
        {
            try
            {
                // create or get input asset
                const response = await dispatch('setInputAssetFromRaw', { additional })

                // call route to generate layer from guidance
                return dispatch('api/layersFromGuidanceGeneratePost', {
                    parentId: response.id,
                    canny,
                    depth,
                    positive,
                    negative,
                    resolution,
                    seeds,
                    additional
                }, { root: true })
            }
            catch(error)
            {
                throw error
            }
        },

        // generate model from input asset
        async generateModelFromInputAsset({ dispatch }, {
            additional = null
        })
        {
            try
            {
                // create or get input asset
                const response = await dispatch('setInputAssetFromRaw', { additional })

                // call route to generate model
                return dispatch('api/modelsFromImageGeneratePost', {
                    parentId: response.id,
                    additional
                }, { root: true })
            }
            catch(error)
            {
                throw error
            }
        },

        // generate model from preview asset
        async generateModelFromPreviewAsset({ dispatch }, {
            parentId,
            additional = null,
            forceImage = false
        })
        {
            // determine correct route
            const route = forceImage ? 'api/modelsFromImageGeneratePost' : 'api/modelsFromLayerGeneratePost'

            // call route to generate model
            return dispatch(route, {
                parentId,
                additional
            }, { root: true })
        },

        setActiveInputRaw({ commit }, raw) {
            commit('SET_ACTIVE_INPUT_RAW', raw)
        },
        setActiveInputStrokes({ commit }, strokes) {
            commit('SET_ACTIVE_INPUT_STROKES', strokes)
        },
        setActiveInputBackground({ commit }, background) {
            commit('SET_ACTIVE_INPUT_BACKGROUND', background)
        },
        setActiveInputUpload({ commit }, upload) {
            commit('SET_ACTIVE_INPUT_UPLOAD', upload)
        },
        setActiveInputAsset({ commit }, asset) {
            commit('SET_ACTIVE_INPUT_ASSET', asset)
        },
        setActivePreviewAssets({ commit }, assets) {
            commit('SET_ACTIVE_PREVIEW_ASSETS', assets)
        },
        setActiveModelAsset({ commit }, asset) {
            commit('SET_ACTIVE_MODEL_ASSET', asset)
        },

        setActiveWindow({ commit }, window) {
            commit('SET_ACTIVE_WINDOW', window)
        },
        setActiveWindowToDefault({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_DEFAULT)
        },
        setActiveWindowToLibrary({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_LIBRARY)
        },
        setActiveWindowToInput({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_INPUT)
        },
        setActiveWindowToPreview({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_PREVIEW)
        },
        setActiveWindowToModel({ commit }) {
            commit('SET_ACTIVE_WINDOW', WINDOW_MODEL)
        },

        setActiveWidget({ commit }, widget) {
            commit('SET_ACTIVE_WIDGET', widget)
        },
        setActiveWidgetToSketch({ commit }) {
            commit('SET_ACTIVE_WIDGET', WIDGET_SKETCH)
        },
        setActiveWidgetToUpload({ commit }) {
            commit('SET_ACTIVE_WIDGET', WIDGET_UPLOAD)
        },
        setActiveWidgetToCamera({ commit }) {
            commit('SET_ACTIVE_WIDGET', WIDGET_CAMERA)
        },

        setInputStrength({ commit }, strength) {
            commit('SET_INPUT_STRENGTH', strength)
        },
        setPromptPositive({ commit }, prompt) {
            commit('SET_PROMPT_POSITIVE', prompt)
        },
        setPromptNegative({ commit }, prompt) {
            commit('SET_PROMPT_NEGATIVE', prompt)
        },

        setHint({ commit }, payload) {
            commit('SET_HINT', payload)
        }
    }
}