// vuex.js store for the GenerIO API


//// IMPORTS
import { base64ToArrayBuffer, downloadFile } from '@/utils/file' 


//// ROUTE CONSTANTS
const ROUTE_BASE = '/api'

// users routes
const ROUTE_USERS_REGISTER = '/users/register'
const ROUTE_USERS_ACTIVATE = '/users/activate'
const ROUTE_USERS_DEACTIVATE = '/users/deactivate'
const ROUTE_USERS_TOKEN = '/users/token'
const ROUTE_USERS_PASSWORD_FORGOT = '/users/password/forgot'
const ROUTE_USERS_PASSWORD_CHANGE = '/users/password/change'
const ROUTE_USERS_STATISTICS = '/users/statistics'
const ROUTE_USERS_DESIGN = '/users/design'
const ROUTE_USERS_NEWSLETTER = '/users/newsletter'
const ROUTE_USERS_ADDITIONAL = '/users/additional'

// app routes
const ROUTE_APPS_APP_ADDITIONAL = (appName) => `/apps/${appName}/additional`

// assets routes
const ROUTE_ASSETS = `/assets`
const ROUTE_ASSETS_ASSET = (assetId) => `${ROUTE_ASSETS}/${assetId}`
const ROUTE_ASSETS_ASSET_ADDITIONAL = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/additional`
const ROUTE_ASSETS_ASSET_STATUS = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/status`
const ROUTE_ASSETS_ASSET_THUMBNAIL = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/thumbnail`
const ROUTE_ASSETS_ASSET_FILES = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/files`
const ROUTE_ASSETS_ASSET_SHARE = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/share`
const ROUTE_ASSETS_ASSET_SHARE_THUMBNAIL = (assetId) => `${ROUTE_ASSETS_ASSET(assetId)}/share/thumbnail`
const ROUTE_ASSETS_ASSET_FILE_KEY = (assetId, key) => `${ROUTE_ASSETS_ASSET(assetId)}/file/${key}`

// texts routes
const ROUTE_TEXTS_GENERIC = '/texts/generic/generate'
const ROUTE_TEXTS_COLOR = '/texts/color/generate'
const ROUTE_TEXTS_TRANSLATION = '/texts/translation/generate' 

// images routes
const ROUTE_IMAGES_FROMLAYER_GENERATE = '/images/from-layer/generate'
const ROUTE_IMAGES_FROMPROMPT_GENERATE = '/images/from-prompt/generate'
const ROUTE_IMAGES_FROMGUIDANCE_GENERATE = '/images/from-guidance/generate'
const ROUTE_IMAGES_IMAGE_CANNY_GENERATE = (imageAssetId) => `/images/${imageAssetId}/canny/generate`
const ROUTE_IMAGES_IMAGE_DEPTH_GENERATE = (imageAssetId) => `/images/${imageAssetId}/depth/generate`
const ROUTE_IMAGES_IMAGE_NORMAL_GENERATE = (imageAssetId) => `/images/${imageAssetId}/normal/generate`
const ROUTE_IMAGES_IMAGE_SEGMENT_GENERATE = (imageAssetId) => `/images/${imageAssetId}/segment/generate`

// layers routes
const ROUTE_LAYERS_FROMIMAGE_GENERATE = '/layers/from-image/generate'
const ROUTE_LAYERS_FROMPROMPT_GENERATE = '/layers/from-prompt/generate'
const ROUTE_LAYERS_FROMGUIDANCE_GENERATE = '/layers/from-guidance/generate'
const ROUTE_LAYERS_LAYER_CANNY_GENERATE = (layerAssetId) => `/layers/${layerAssetId}/canny/generate`
const ROUTE_LAYERS_LAYER_DEPTH_GENERATE = (layerAssetId) => `/layers/${layerAssetId}/depth/generate`
const ROUTE_LAYERS_LAYER_NORMAL_GENERATE = (layerAssetId) => `/layers/${layerAssetId}/normal/generate`
const ROUTE_LAYERS_LAYER_SEGMENT_GENERATE = (layerAssetId) => `/layers/${layerAssetId}/segment/generate`

// textures routes
const ROUTE_TEXTURES_BASE_GENERATE = '/textures/base/generate'
const ROUTE_TEXTURES_TEXTURE_DEPTH_GENERATE = (textureAssetId) => `/textures/${textureAssetId}/depth/generate`
const ROUTE_TEXTURES_TEXTURE_NORMAL_GENERATE = (textureAssetId) => `/textures/${textureAssetId}/normal/generate`

// models routes
const ROUTE_MODELS_FROMIMAGE_GENERATE = '/models/from-image/generate'
const ROUTE_MODELS_FROMLAYER_GENERATE = '/models/from-layer/generate' 
const ROUTE_MODELS_FROMSCRIPT_GENERATE = '/models/from-script/generate'
const ROUTE_MODELS_FROMFILE_CONVERT = '/models/from-file/convert'
const ROUTE_MODELS_MODEL_CONVERT = (modelAssetId) => `/models/${modelAssetId}/convert`
const ROUTE_MODELS_MODEL_REDUCE = (modelAssetId) => `/models/${modelAssetId}/reduce`
const ROUTE_MODELS_MODEL_OPTIMIZE = (modelAssetId) => `/models/${modelAssetId}/optimize`
const ROUTE_MODELS_MODEL_THUMBNAIL_GENERATE = (modelAssetId) => `/models/${modelAssetId}/thumbnail/generate`


// logging routes
const ROUTE_LOGGING_EVENT = '/logging/event'
const ROUTE_LOGGING_BUG = '/logging/bug'
const ROUTE_LOGGING_FEEDBACK = '/logging/feedback'
const ROUTE_LOGGING_VOTE = '/logging/vote'

// monitoring routes
const ROUTE_MONITORING_STATUS = '/monitoring/status'

// administration routes
const ROUTE_ADMINISTRATION_ACTIVITY_USERS = '/administration/activity/users'
const ROUTE_ADMINISTRATION_ACTIVITY_ASSETS = '/administration/activity/assets'
const ROUTE_ADMINISTRATION_LOGGING_EVENTS = '/administration/logging/events'
const ROUTE_ADMINISTRATION_LOGGING_BUGS = '/administration/logging/bugs'
const ROUTE_ADMINISTRATION_LOGGING_FEEDBACK = '/administration/logging/feedback'
const ROUTE_ADMINISTRATION_LOGGING_VOTES = '/administration/logging/votes'
const ROUTE_ADMINISTRATION_NEWSLETTERS = '/administration/newsletters'
const ROUTE_ADMINISTRATION_NEWSLETTERS_SEND = '/administration/newsletters/send'


//// REST CONSTANTS
const REST_POST = 'POST'
const REST_GET = 'GET'
const REST_PUT = 'PUT'
const REST_DELETE = 'DELETE'


//// SERVICE CONSTANTS

// status
const SERVICE_STATUS_UP = 'up'
const SERVICE_STATUS_DOWN = 'down'
const SERVICE_STATUS_UNDEFINED = 'unknown'


//// ASSET CONSTANTS

// types
const ASSET_TYPE_TEXT = 'text'
const ASSET_TYPE_IMAGE = 'image'
const ASSET_TYPE_LAYER = 'layer'
const ASSET_TYPE_TEXTURE = 'texture'
const ASSET_TYPE_MODEL = 'model'

// status
const ASSET_STATUS_UNREQUESTED = 'unrequested'
const ASSET_STATUS_REQUESTED = 'requested'
const ASSET_STATUS_RETRIEVED = 'retrieved'
const ASSET_STATUS_UNRETRIEVABLE = 'unretrievable'


//// VUEX STORE
export const api = {
    namespaced: true,
    state: {
        service: {
            status: SERVICE_STATUS_UNDEFINED,
            queues: null
        },
        assets: [],
        votes: {}
    },
    mutations: {
        // set service
        SET_SERVICE(state, { status, queues }) 
        {
            state.service.status = status
            state.service.queues = queues
        },
        // set service status
        SET_SERVICE_STATUS(state, status) 
        {
            state.service.status = status
        },

        // reset assets
        RESET_ASSETS(state) 
        {
            state.assets = []
        },

        // add asset
        ADD_ASSET(state, asset) 
        {
            // ensure correct format
            asset.created = new Date(asset.created)
            // push asset to assets list
            state.assets.push(asset)
        },

        // remove asset
        REMOVE_ASSET(state, assetId) 
        {
            // find index of asset in assets
            const index = state.assets.findIndex(asset => asset.id === assetId)
            // if it exists in the list, remove it
            if (index !== -1) state.assets.splice(index, 1)
        },

        // set a specific vote with a key (value true/false)
        SET_VOTE(state, { key, value }) 
        {
            state.votes[key] = value
        }
    },
    getters: {
        keyServiceStatusUp: () => SERVICE_STATUS_UP,
        keyServiceStatusDown: () => SERVICE_STATUS_DOWN,
        keyServiceStatusUndefned: () => SERVICE_STATUS_UNDEFINED,

        keyAssetTypeText: () => ASSET_TYPE_TEXT,
        keyAssetTypeImage: () => ASSET_TYPE_IMAGE,
        keyAssetTypeLayer: () => ASSET_TYPE_LAYER,
        keyAssetTypeTexture: () => ASSET_TYPE_TEXTURE,
        keyAssetTypeModel: () => ASSET_TYPE_MODEL,

        keyAssetStatusUnrequested: () => ASSET_STATUS_UNREQUESTED,
        keyAssetStatusRequested: () => ASSET_STATUS_REQUESTED,
        keyAssetStatusRetrieved: () => ASSET_STATUS_RETRIEVED,
        keyAssetStatusUnretrievable: () => ASSET_STATUS_UNRETRIEVABLE,

        isServiceStatusUp: state => state.service.status === SERVICE_STATUS_UP,
        isServiceStatusDown: state => state.service.status === SERVICE_STATUS_DOWN,
        isServiceStatusUndefined: state => state.service.status === SERVICE_STATUS_UNDEFINED,

        isAssetTypeText: () => (asset) => asset.type === ASSET_TYPE_TEXT,
        isAssetTypeImage: () => (asset) => asset.type === ASSET_TYPE_IMAGE,
        isAssetTypeLayer: () => (asset) => asset.type === ASSET_TYPE_LAYER,
        isAssetTypeTexture: () => (asset) => asset.type === ASSET_TYPE_TEXTURE,
        isAssetTypeModel: () => (asset) => asset.type === ASSET_TYPE_MODEL,

        hasVote: state => (key) => key in state.votes,

        getServiceStatus: state => state.service.status,
        getServiceQueues: state => state.service.queues,

        getAssets: state => state.assets,
        getAsset: state => (assetId) => {
            return state.assets.find(asset => asset.id === assetId)
        },
        getTextAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_TEXT)
        },
        getImageAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_IMAGE)
        },
        getLayerAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_LAYER)
        },
        getTextureAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_TEXTURE)
        },
        getModelAssets: state => {
            return state.assets.filter(asset => asset.type === ASSET_TYPE_MODEL)
        },
        getFilteredAssets: state => (app = null, type = null, generated = null) => {
            return state.assets.filter(asset => {
                return (app === null || asset.app === app) &&
                       (type === null || asset.type === type) &&
                       (generated === null || asset.generated === generated)
            })
        },
        getChildAssets: state => (assetId) => {
            return state.assets.filter(asset => asset.parent === assetId)
        },

        getVote: state => (key) => {
            return state.votes[key]
        }
    },
    actions: {

        //// INITIALIZATION

        // initialize the API
        async initializeAPI({ state, commit, dispatch, getters, rootGetters })
        {
            // get monitoring status
            dispatch('monitoringStatusGet')

            // empty list of assets
            commit('RESET_ASSETS')

            // get all assets if user is authenticated
            // IMPORTANT: requires initializeApp to be called before initializeAPI
            if (rootGetters['app/isAuthenticated']) dispatch('assetsGet')
        },



        //// USERS 

        // users register (post)
        async usersRegisterPost({ dispatch }, { 
            email, 
            mode = 'dark', 
            theme = 1, 
            additional = null
        }) 
        {
            // ensure email parameter is provided
            if (email === null || email === undefined) {
                throw new Error("Please provide an email to register your account.")
            }

            // payload
            const payload = {
                email,
                mode,
                theme,
                additional
            }
        
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_REGISTER,
                method: REST_POST,
                payload
            })
        },

        // users activate (put)
        async usersActivatePut({ dispatch }, {
            token
        })
        {
            // ensure token parameter is provided
            if (token === null || token === undefined) {
                throw new Error("Please provide a token to activate your account.")
            }

            // payload
            const payload = {
                token
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_ACTIVATE,
                method: REST_PUT,
                payload
            })
        },

        // users deactivate (put)
        async usersDeactivatePut({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_DEACTIVATE, 
                method: REST_PUT
            })
        },

        // users token (post)
        async usersTokenPost({ dispatch }, {
            email,
            password
        })
        {
            // ensure email parameter is provided
            if (email === null || email === undefined) {
                throw new Error("Please provide an email to login.")
            }

            // ensure password parameter is provided
            if (password === null || password === undefined) {
                throw new Error("Please provide a password to login.")
            }

            // payload
            const payload = {
                email,
                password
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_TOKEN,
                method: REST_POST,
                payload
            })
        },

        // users password forgot (post)
        async usersPasswordForgotPost({ dispatch }, { 
            email
        })
        {
            // ensure email parameter is provided
            if (email === null || email === undefined) {
                throw new Error("Please provide an email to reset your password.")
            }

            // payload
            const payload = {
                email
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_PASSWORD_FORGOT,
                method: REST_POST,
                payload
            })
        },

        // users password change (put)
        async usersPasswordChangePut({ dispatch }, { 
            token, 
            password
        })
        {
            // ensure password parameter is provided
            if (password === null || password === undefined) {
                throw new Error("Please provide a new password to change your old password.")
            }

            // payload
            const payload = {
                new_password: password
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_PASSWORD_CHANGE, 
                method: REST_PUT,
                payload, 
                token
            })
        },

        // users statistics (get)
        async usersStatisticsGet({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_STATISTICS, 
                method: REST_GET
            })
        },

        // users design (get)
        async usersDesignGet({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_DESIGN,
                method: REST_GET
            })
        },

        // users design (put)
        async usersDesignPut({ dispatch }, { 
            mode = 'dark', 
            theme = 0
        })
        {
            // payload
            const payload = {
                mode, 
                theme
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_DESIGN,
                method: REST_PUT,
                payload
            })
        },

        // users newsletter (get)
        async usersNewsletterGet({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_NEWSLETTER,
                method: REST_GET
            })
        },

        // users newsletter (post)
        async usersNewsletterPost({ dispatch }, { 
            subscribed = false
        })
        {
            // payload
            const payload = {
                subscribed
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_NEWSLETTER,
                method: REST_POST,
                payload
            })
        },

        // users additional (get)
        async usersAdditionalGet({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_ADDITIONAL,
                method: REST_GET
            })
        },

        // users additional (put)
        async usersAdditionalPut({ dispatch }, { 
            additional = null
        })
        {
            // payload
            const payload = {
                additional
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_USERS_NEWSLETTER,
                method: REST_POST,
                payload
            })
        },



        //// APPS

        // apps {app} additional (get)
        async appsAppAdditionalGet({ dispatch, rootGetters })
        {
            // get app name
            const app = rootGetters['app/getAppname']

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_APPS_APP_ADDITIONAL(app),
                method: REST_GET
            })
        },

        // apps {app} additional (put)
        async appsAppAdditionalPut({ dispatch, rootGetters }, {
            additional = null
        })
        {
            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                additional
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_APPS_APP_ADDITIONAL(app),
                method: REST_PUT,
                payload
            })
        },



        //// ASSETS

        // assets (get)
        async assetsGet({ commit, dispatch }) 
        {
            try 
            {
                // send API request and await the response
                const response = await dispatch('requestApi', {
                    url: ROUTE_ASSETS,
                    method: REST_GET
                })
        
                // clear the list of assets
                commit('RESET_ASSETS')
        
                // iterate through all assets and add them
                response.forEach(asset => {
                    commit('ADD_ASSET', asset)
                })
        
                return response
            } 
            catch (error) 
            {
                throw error
            }
        },

        // assets (post)
        async assetsPost({ commit, rootGetters, dispatch }, { 
            type, 
            file, 
            thumbnail = '', 
            additional = null
        }) 
        {
            // ensure url parameter type is present
            if (type === null || type === undefined) {
                throw new Error("Please provide an asset type.")
            }

            // ensure url parameter file is present
            if (file === null || file === undefined) {
                throw new Error("Please provide a file for the asset.")
            }

            try 
            {
                // get app name
                const app = rootGetters['app/getAppname']
        
                // prepare server request payload
                const payload = {
                    app, 
                    type, 
                    file, 
                    thumbnail, 
                    additional
                }
        
                // send API request and await the response
                const response = await dispatch('requestApi', {
                    url: ROUTE_ASSETS,
                    method: REST_POST,
                    payload
                })
        
                // add local copy of asset
                commit('ADD_ASSET', response)
        
                // update thumbnail
                response.thumbnail.data = thumbnail
                response.thumbnail.status = ASSET_STATUS_RETRIEVED
        
                // update files
                response.files['default'].data = file
                response.files['default'].status = ASSET_STATUS_RETRIEVED

                return response
            } 
            catch (error) 
            {
                throw error
            }
        },

        // assets {asset} (get)
        async assetsAssetGet({ dispatch }, {
            assetId
        })
        {
            // ensure url parameter are present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ASSETS_ASSET(assetId),
                method: REST_GET
            })
        },

        // assets {asset} (delete)
        async assetsAssetDelete({ commit, dispatch }, {
            assetId
        })
        {
            // ensure url parameter are present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            try 
            {
                // send API request and await the response
                const response = await dispatch('requestApi', {
                    url: ROUTE_ASSETS_ASSET(assetId),
                    method: REST_DELETE
                })

                // remove local copy of asset
                commit('REMOVE_ASSET', assetId)

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // assets {asset} additional (get)
        async assetsAssetAdditionalGet({ dispatch }, {
            assetId
        })
        {
            // ensure url parameter are present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ASSETS_ASSET_ADDITIONAL(assetId),
                method: REST_GET
            })
        },

        // assets {asset} additional (put)
        async assetsAssetAdditionalPut({ dispatch }, {
            assetId,
            additional
        })
        {
            // ensure url parameter are present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            // payload
            const payload = {
                additional
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ASSETS_ASSET_ADDITIONAL(assetId),
                method: REST_PUT,
                payload
            })
        },

        // assets {asset} status (get)
        async assetsAssetStatusGet({ dispatch }, { 
            assetId, 
            untilReady = true,
            timeIncrement = 500, // in milliseconds
            timeMaximum = 10000, // in milliseconds
        }) 
        {
            // ensure url parameter is present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }
        
            // save how much time has passed
            let timePassed = 0
        
            // helper function to delay execution
            const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))
        
            // function to poll the status until ready
            const requestStatus = async (timeCurrent) => {
                try 
                {
                    // send API request to get asset status
                    const response = await dispatch('requestApi', {
                        url: ROUTE_ASSETS_ASSET_STATUS(assetId),
                        method: REST_GET
                    })
        
                    // if untilReady is true and the asset is not ready, poll again after a delay
                    if (untilReady && !response.ready && timePassed < timeMaximum) 
                    {
                        // wait for the specified time increment
                        await delay(timeCurrent)

                        // update passed time
                        timePassed += timeCurrent
        
                        // recursively call the requestStatus function to check again
                        return requestStatus(timeCurrent + timeIncrement)
                    } 
                    else 
                    {
                        return response
                    }
                } 
                catch (error) 
                {
                    throw error
                }
            }
        
            // start the polling process
            return requestStatus(timeIncrement)
        },

        // assets {asset} thumbnail (get)
        async assetsAssetThumbnailGet({ dispatch }, { 
            asset, 
            animate = true,
            timeMaximum = 10000 // in miliseconds
        }) 
        {
            // ensure the asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }
        
            // set status to indicate thumbnail is being requested
            asset.thumbnail.status = ASSET_STATUS_REQUESTED
        
            // helper function to request the thumbnail
            const requestThumbnail = async () => {
                try 
                {
                    // payload
                    const payload = {
                        animate
                    }
        
                    // send API request to get the thumbnail
                    const response = await dispatch('requestApi', {
                        url: asset.thumbnail.url,
                        method: REST_GET,
                        payload
                    })
        
                    // update thumbnail of asset
                    asset.thumbnail.data = response
                    asset.thumbnail.status = ASSET_STATUS_RETRIEVED
        
                    return response
                } 
                catch (error) 
                {
                    // update thumbnail status
                    asset.thumbnail.status = ASSET_STATUS_RETRIEVED
        
                    // throw the error to propagate it upwards
                    throw error
                }
            }
        
            // wait for asset to be ready
            try 
            {
                // ensure the asset status is ready before requesting the thumbnail
                await dispatch('assetsAssetStatusGet', { 
                    assetId: asset.id,
                    timeMaximum
                })
        
                // after asset status is ready, request the thumbnail
                const thumbnail = await requestThumbnail()
                
                return thumbnail
            } 
            catch (error) 
            {
                throw error
            }
        },
        
        // assets {asset} files (get)
        async assetsAssetFilesGet({ dispatch }, { 
            asset 
        }) 
        {
            // ensure asset parameter is present
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }
        
            // set the status to indicate that files are being requested
            for (const key in asset.files) {
                asset.files[key].status = ASSET_STATUS_REQUESTED
            }
        
            // helper function to request the files
            const requestFiles = async () => {
                try 
                {
                    // send API request to get the asset files
                    const response = await dispatch('requestApi', {
                        url: ROUTE_ASSETS_ASSET_FILES(asset.id),
                        method: REST_GET
                    })
        
                    // iterate through files and update their status and data
                    for (const key in asset.files) {
                        asset.files[key].status = ASSET_STATUS_RETRIEVED
                        asset.files[key].data = response[key].data
                        asset.files[key].details = response[key].details
                    }
        
                    return response
                } 
                catch (error) 
                {
                    // handle any errors: mark all files as retrieved (to avoid loops)
                    for (const key in asset.files) {
                        asset.files[key].status = ASSET_STATUS_RETRIEVED
                    }

                    // propagate the error upwards
                    throw error
                }
            }
        
            // wait for asset to be ready
            try {
                // ensure the asset status is ready before requesting the files
                await dispatch('getAssetStatus', { asset })
        
                // after the asset status is ready, request the files
                const files = await requestFiles()
        
                return files
            } 
            catch (error) 
            {
                throw error
            }
        },

        // assets {asset} share (get)
        async assetsAssetShareGet({ dispatch }, {
            assetId
        })
        {
            // IMPORTANT get shared asset not stored in assets state

            // ensure url parameter is present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ASSETS_ASSET_SHARE(assetId),
                method: REST_GET
            })
        },

        // assets {asset} share (put)
        async assetsAssetSharePut({ dispatch }, { 
            asset, 
            shared = false 
        }) 
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }
        
            // payload to send with the request
            const payload = {
                shared: shared
            }
        
            try 
            {
                // send API request to update the shared status
                const response = await dispatch('requestApi', {
                    url: ROUTE_ASSETS_ASSET_SHARE(asset.id),
                    method: REST_PUT,
                    payload
                })
        
                // update the asset's shared field based on the response
                asset.shared = response.shared

                return response
            } 
            catch (error) 
            {
                throw error
            }
        },

        // assets {asset} share thumbnail (get)
        async assetsAssetShareThumbnailGet({ dispatch }, {
            assetId
        })
        {
            // IMPORTANT get shared asset thumbnail not stored in assets state

            // ensure url parameter is present
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id.")
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ASSETS_ASSET_SHARE_THUMBNAIL(assetId),
                method: REST_GET
            })
        },

        // assets {asset} file {key} (get)
        async assetsAssetFileKeyGet({ dispatch, getters }, { 
            asset, 
            key = 'default',
            timeMaximum = 30000 // in milliseconds (30 secs)
        }) 
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset.")
            }

            // ensure asset has a file with the specified key
            if (!asset.files.hasOwnProperty(key)) {
                throw new Error(`File key ('${key}') not found in asset.`)
            }
        
            // get file referenced by key
            const file = asset.files[key]
        
            // if the file has already been retrieved, return it directly
            if (file.status === getters.keyAssetStatusRetrieved) {
                return { data: file.data, details: file.details }
            }
        
            // set status to indicate the file is being requested
            file.status = ASSET_STATUS_REQUESTED
        
            // helper function to request the file from the server
            const requestFile = async () => {
                try 
                {
                    // send the API request to get the file
                    const response = await dispatch('requestApi', {
                        url: file.url,
                        method: REST_GET
                    })
        
                    // update file status and data
                    file.data = response.data
                    file.details = response.details
                    file.status = ASSET_STATUS_RETRIEVED
        
                    return response
                } 
                catch (error) 
                {
                    // set status to indicate the file has been retrieved, even if there was an error
                    file.status = ASSET_STATUS_RETRIEVED
        
                    // propagate the error up the chain
                    throw error
                }
            }
        
            // wait for the asset to be ready
            try {
                // once the asset is ready
                await dispatch('assetsAssetStatusGet', { 
                    assetId: asset.id,
                    timeMaximum
                })
        
                // request the file
                const response = await requestFile()
        
                return response
            } 
            catch (error) 
            {
                throw error
            }
        },

        // helper function to simplify downloads (not directly an API route)
        async assetsAssetDownloadHelper({ dispatch, getters }, {
            asset,
            key = 'default',
            format = null
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide an asset to download.")
            }

            try
            {
                // call route to retrieve asset file
                const response = await dispatch('assetsAssetFileKeyGet', {
                    asset,
                    key
                })

                // get asset base file data
                const base64Data = asset.files[key].data

                const name = `${asset.type}_${asset.id}`
                
                // create download based on asset type
                if (getters.isAssetTypeModel(asset))
                {
                    if (format == null || format == 'glb')
                    {
                        const binaryData = base64ToArrayBuffer(base64Data)
                        downloadFile(binaryData, 'model/gltf-binary', name, 'glb')
                    }
                    else
                    {
                        // call route to convert model asset
                        const responseConvert = await dispatch('modelsModelConvertPost', {
                            assetId: asset.id,
                            format
                        })

                        const binaryData = base64ToArrayBuffer(responseConvert)
                        downloadFile(binaryData, 'model/' + format, name, format)

                        return responseConvert
                    }
                }
                else
                {
                    const binaryData = base64ToArrayBuffer(base64Data)
                    downloadFile(binaryData, 'image/png', name, 'png')
                }

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        


        //// TEXT

        // texts generic generate (post)
        async textsGenericGeneratePost({ dispatch }, {
            prompt
        })
        {
            // TODO 
            // call API
            // store returning asset 
        },

        // texts color generate (post)
        async textsColorGeneratePost({ dispatch }, { 
            prompt
        })
        {
            // TODO 
            // call API
            // store returning asset

            /*
            // new material component for the generated color
            const component = getters.newComponent('color', 'generic', prompt)

            // add material component to store
            commit('ADD_COMPONENT', component)

            // function for error handling
             const callbackErrorWrapper = (error) => {

                // remove from list again
                dispatch('removeComponent', component.id)

                // trigger callback
                if (callbackError && typeof callbackError === 'function')
                    callbackError(error)
            } 

            // function for success handling
            const callbackSuccessWrapper = (result) => {

                // extract json
                const json = result.response

                // check if json is valid
                if (json)
                {
                    // get diffuse color
                    if (json.Diffuse && json.Diffuse.r && json.Diffuse.g && json.Diffuse.b)
                    {
                        // large value range (0.255)
                        const largerRange = json.Diffuse.r > 1 || 
                                            json.Diffuse.g > 1 || 
                                            json.Diffuse.b > 1

                        component.data.diffuseColor = {
                            r: largerRange ? json.Diffuse.r : Math.floor(json.Diffuse.r * 255),
                            g: largerRange ? json.Diffuse.g : Math.floor(json.Diffuse.g * 255),
                            b: largerRange ? json.Diffuse.b : Math.floor(json.Diffuse.b * 255),
                        }
                    }

                    // get emissive color
                    if (json.Emissive && json.Emissive.r && json.Emissive.g && json.Emissive.b)
                    {
                        // large value range (0.255)
                        const largerRange = json.Emissive.r > 1 || 
                                            json.Emissive.g > 1 || 
                                            json.Emissive.b > 1

                        component.data.emissiveColor = {
                            r: largerRange ? json.Emissive.r : Math.floor(json.Emissive.r * 255),
                            g: largerRange ? json.Emissive.g : Math.floor(json.Emissive.g * 255),
                            b: largerRange ? json.Emissive.b : Math.floor(json.Emissive.b * 255),
                        }
                    }

                    // get emissive exponent
                    if (json['Emissive Strength'])
                    {
                        const exp = Number(json['Emissive Strength'])
                        component.data.emissiveIntensity = Math.max(Math.min(exp, 1), 0)
                    }

                    // get advanced attributes
                    if (json.Roughness) 
                        component.data.roughness = json.Roughness
                    if (json.Metalness) 
                        component.data.metalness = json.Metalness

                    // set component as ready
                    component.ready = true

                    // trigger callback
                    if (callbackSuccess && typeof callbackSuccess === 'function')
                        callbackSuccess(component)
                }
                else 
                    errorHandling("Color could not be parsed.")
            }

            // api is active
            if (rootState.api.active)
            {
                // prepare server request
                const payload = {
                    query: component.prompt
                }

                // send server request
                await accessRoute(
                    ROUTE_TEXTS_COLOR, 
                    payload, 
                    REST_POST,
                    null,
                    callbackSuccessWrapper,
                    callbackErrorWrapper
                )
            }
            */
        },

        // texts translation generate (post)
        async textsTranslationGeneratePost({ dispatch }, { 
            prompt,
            language
        })
        {
            // TODO
            // send request
            // store returning asset
        },



        //// IMAGES

        // images from prompt generate (post)
        async imagesFromPromptGeneratePost({ commit, dispatch, rootGetters }, {
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1, 
            steps = 4,
            cfg = 2,
            seeds = [ -1 ], 
            additional = null
        })
        {
            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                prompt: {
                    positive,
                    negative
                },
                generation: {
                    resolution,
                    denoising, 
                    steps,
                    cfg
                },
                seeds,
                additional,
                app
            }

            try
            {
                // send API request to generate images from prompt
                const response = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_FROMPROMPT_GENERATE,
                    method: REST_POST,
                    payload
                })

                // iterate through response
                response.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_ASSET', asset)
                })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // images from guidance generate (post)
        async imagesFromGuidanceGeneratePost({ commit, dispatch, rootGetters }, {
            parentId,
            canny = 0,
            depth = 0,
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1,
            steps = 4,
            cfg = 2,
            seeds = [ -1 ],
            additional = null
        })
        {
            // ensure parentId parameter is provided
            if (parentId === null || parentId === undefined) {
                throw new Error("Please provide a guiding asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                guidance: {
                    parent: parentId,
                    canny,
                    depth
                },
                prompt: {
                    positive,
                    negative
                },
                generation: {
                    resolution,
                    denoising, 
                    steps,
                    cfg
                },
                seeds,
                additional,
                app
            }

            try
            {
                // send API request to generate images from guidance
                const response = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_FROMGUIDANCE_GENERATE,
                    method: REST_POST,
                    payload
                })

                // iterate through response
                response.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_ASSET', asset)
                })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} canny generate (post)
        async imagesImageCannyGeneratePost({ dispatch }, {
            asset,
            resolution = 512
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // payload
            const payload = {
                resolution
            }

            try
            {
                // send API request to generate image canny
                const response = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_CANNY_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update canny file with response
                asset.files['canny'] = response.files['canny']

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} depth generate (post)
        async imagesImageDepthGeneratePost({ dispatch }, {
            asset,
            resolution = 512
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // payload
            const payload = {
                resolution
            }

            try
            {
                // send API request to generate image depth
                const response = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_DEPTH_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update depth file with response
                asset.files['depth'] = response.files['depth']

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} normal generate (post)
        async imagesImageNormalGeneratePost({ dispatch }, {
            asset,
            resolution = 512
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            // payload
            const payload = {
                resolution
            }

            try
            {
                // send API request to generate image normal
                const response = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_NORMAL_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update normal file with response
                asset.files['normal'] = response.files['normal']

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // images {image} segment generate (post)
        async imagesImageSegmentGeneratePost({ dispatch }, {
            asset
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a image asset.")
            }

            try
            {
                // send API request to generate image segment
                const response = await dispatch('requestApi', {
                    url: ROUTE_IMAGES_IMAGE_SEGMENT_GENERATE(asset.id),
                    method: REST_POST
                })

                // update segment file with response
                asset.files['segment'] = response.files['segment']

                return response
            }
            catch(error)
            {
                throw error
            }
        },



        //// LAYERS

        // layers from prompt generate (post)
        async layersFromPromptGeneratePost({ commit, dispatch, rootGetters }, {
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1, 
            steps = 4,
            cfg = 2,
            seeds = [ -1 ], 
            additional = null
        })
        {
            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                prompt: {
                    positive,
                    negative
                },
                generation: {
                    resolution,
                    denoising, 
                    steps,
                    cfg
                },
                seeds,
                additional,
                app
            }

            try
            {
                // send API request to generate layers from prompt
                const response = await dispatch('requestApi', {
                    url: ROUTE_LAYERS_FROMPROMPT_GENERATE,
                    method: REST_POST,
                    payload
                })

                // iterate through response
                response.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_ASSET', asset)
                })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // layers from guidance generate (post)
        async layersFromGuidanceGeneratePost({ commit, dispatch, rootGetters }, {
            parentId,
            canny = 0,
            depth = 0,
            positive = '',
            negative = '',
            resolution = 512,
            denoising = 1,
            steps = 4,
            cfg = 2,
            seeds = [ -1 ],
            additional = null
        })
        {
            // ensure parentId parameter is provided
            if (parentId === null || parentId === undefined) {
                throw new Error("Please provide a guiding asset.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                guidance: {
                    parent: parentId,
                    canny,
                    depth
                },
                prompt: {
                    positive,
                    negative
                },
                generation: {
                    resolution,
                    denoising, 
                    steps,
                    cfg
                },
                seeds,
                additional,
                app
            }

            try
            {
                // send API request to generate layers from guidance
                const response = await dispatch('requestApi', {
                    url: ROUTE_LAYERS_FROMGUIDANCE_GENERATE,
                    method: REST_POST,
                    payload
                })

                // iterate through response
                response.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_ASSET', asset)
                })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // layers {layer} canny generate (post)
        async layersLayerCannyGeneratePost({ dispatch }, {
            asset,
            resolution = 512
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a layer asset.")
            }

            // payload
            const payload = {
                resolution
            }

            try
            {
                // send API request to generate layer canny
                const response = await dispatch('requestApi', {
                    url: ROUTE_LAYERS_LAYER_CANNY_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update canny file with response
                asset.files['canny'] = response.files['canny']

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // layers {layer} depth generate (post)
        async layersLayerDepthGeneratePost({ dispatch }, {
            asset,
            resolution = 512
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a layer asset.")
            }

            // payload
            const payload = {
                resolution
            }

            try
            {
                // send API request to generate layer depth
                const response = await dispatch('requestApi', {
                    url: ROUTE_LAYERS_LAYER_DEPTH_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update depth file with response
                asset.files['depth'] = response.files['depth']

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // layers {layer} normal generate (post)
        async layersLayerNormalGeneratePost({ dispatch }, {
            asset,
            resolution = 512
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a layer asset.")
            }

            // payload
            const payload = {
                resolution
            }

            try
            {
                // send API request to generate layer normal
                const response = await dispatch('requestApi', {
                    url: ROUTE_LAYERS_LAYER_NORMAL_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update normal file with response
                asset.files['normal'] = response.files['normal']

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // layers {layer} segment generate (post)
        async layersLayerSegmentGeneratePost({ dispatch }, {
            asset
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a layer asset.")
            }

            try
            {
                // send API request to generate layer segment
                const response = await dispatch('requestApi', {
                    url: ROUTE_LAYERS_LAYER_SEGMENT_GENERATE(asset.id),
                    method: REST_POST
                })

                // update segment file with response
                asset.files['segment'] = response.files['segment']

                return response
            }
            catch(error)
            {
                throw error
            }
        },


        //// TEXTURES

        // textures base generate (post)
        async texturesBaseGeneratePost({ commit, dispatch, rootGetters }, {
            positive = '',
            negative = '',
            parentId = null,
            canny = 0,
            depth = 0,
            resolution = 512,
            denoising = 1,
            steps = 4,
            cfg = 2,
            seeds = [ -1 ],
            additional = null
        })
        {
            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                prompt: {
                    positive,
                    negative
                },
                guidance: {
                    parent: parentId,
                    canny,
                    depth
                },
                generation: {
                    resolution,
                    denoising, 
                    steps,
                    cfg
                },
                seeds,
                additional,
                app
            }

            try
            {
                // send API request to generate base texture
                const response = await dispatch('requestApi', {
                    url: ROUTE_TEXTURES_BASE_GENERATE,
                    method: REST_POST,
                    payload
                })

                // iterate through response
                response.forEach(asset => {

                    // add asset to assets list
                    commit('ADD_ASSET', asset)
                })

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // textures {texture} depth generate (post)
        async texturesTextureDepthGeneratePost({ dispatch }, {
            asset,
            resolution = 512
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a texture asset.")
            }

            // payload
            const payload = {
                resolution
            }

            try 
            {
                // send API request to generate depth texture
                const response = await dispatch('requestApi', {
                    url: ROUTE_TEXTURES_TEXTURE_DEPTH_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update depth file with response
                asset.files['depth'] = response.files['depth']

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // textures {texture} normal generate (post)
        async texturesTextureNormalGeneratePost({ dispatch }, {
            asset,
            resolution = 512
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a texture asset.")
            }

            // payload
            const payload = {
                resolution
            }

            try 
            {
                // send API request to generate normal texture
                const response = await dispatch('requestApi', {
                    url: ROUTE_TEXTURES_TEXTURE_NORMAL_GENERATE(asset.id),
                    method: REST_POST,
                    payload
                })

                // update normal file with response
                asset.files['normal'] = response.files['normal']

                return response
            }
            catch(error)
            {
                throw error
            }
        },



        //// MODELS

        // models from image generate (post)
        async modelsFromImageGeneratePost({ commit, dispatch, rootGetters }, {
            parentId, 
            resolution = 1024,
            scale = 0.8,
            additional = null
        })
        {
            // ensure parentId parameter is provided
            if (parentId === null || parentId === undefined) {
                throw new Error("Please provide a parent asset to generate a model.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                parent: parentId,
                resolution,
                scale,
                additional,
                app 
            }

            try 
            {
                // send API request to generate model
                const response = await dispatch('requestApi', {
                    url: ROUTE_MODELS_FROMIMAGE_GENERATE,
                    method: REST_POST,
                    payload
                })

                // add asset to assets list
                commit('ADD_ASSET', response)

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // models from layer generate (post)
        async modelsFromLayerGeneratePost({ commit, dispatch, rootGetters }, {
            parentId, 
            resolution = 1024,
            scale = 0.8,
            additional = null
        })
        {
            // ensure parentId parameter is provided
            if (parentId === null || parentId === undefined) {
                throw new Error("Please provide a parent asset to generate a model.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                parent: parentId,
                resolution,
                scale,
                additional,
                app 
            }

            try 
            {
                // send API request to generate model
                const response = await dispatch('requestApi', {
                    url: ROUTE_MODELS_FROMLAYER_GENERATE,
                    method: REST_POST,
                    payload
                })

                // add asset to assets list
                commit('ADD_ASSET', response)

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // models from script generate (post)
        async modelsFromScriptGeneratePost({ commit, dispatch, rootGetters }, {
            script,
            libraries = [],
            additional = null
        })
        {
            // ensure script parameter is provided
            if (script === null || script === undefined) {
                throw new Error("Please provide a script to generate a model.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                script,
                libraries, 
                additional,
                app 
            }

            try 
            {
                // send API request to generate model
                const response = await dispatch('requestApi', {
                    url: ROUTE_MODELS_FROMSCRIPT_GENERATE,
                    method: REST_POST,
                    payload
                })

                // add asset to assets list
                commit('ADD_ASSET', response)

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // models from file convert (post)
        async modelsFromFileConvertPost({ commit, dispatch, rootGetters }, {
            file,
            additional = null
        })
        {
            // ensure file parameter is provided
            if (file === null || file === undefined) {
                throw new Error("Please provide a file to convert.")
            }

            // get app name
            const app = rootGetters['app/getAppname']

            // payload
            const payload = {
                file,
                app,
                additional
            }

            try 
            {
                // send API request to generate model
                const response = await dispatch('requestApi', {
                    url: ROUTE_MODELS_FROMFILE_CONVERT,
                    method: REST_POST,
                    payload
                })

                // add asset to assets list
                commit('ADD_ASSET', response)

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // models {model} convert (post)
        async modelsModelConvertPost({ dispatch }, {
            assetId,
            format = 'obj'
        })
        {
            // IMPORTANT convert does not save the asset in the vuex state

            // ensure asset parameter is provided
            if (assetId === null || assetId === undefined) {
                throw new Error("Please provide an asset id for the model.")
            }

            // payload
            const payload =  {
                format
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_MODELS_MODEL_CONVERT(assetId),
                method: REST_POST,
                payload
            })
        },

        // models {model} reduce (post)
        async modelsModelReducePost({ dispatch }, {
            asset,
            decimation = 0
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a model asset.")
            }

            // payload
            const payload =  {
                decimation
            }

            try 
            {
                // send API request to reduce polygons in model
                const response = await dispatch('requestApi', {
                    url: ROUTE_MODELS_MODEL_REDUCE(asset.id),
                    method: REST_POST,
                    payload
                })

                // set asset file lowpoly from response
                asset.files['lowpoly'] = response.files['lowpoly']

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // models {model} optimize (post)
        async modelsModelOptimizePost({ dispatch }, {
            asset,
            centering_active = true,
            reducing_active = false,
            reducing_tolerance = 1e-4,
            inflating_active = false,
            inflating_direction = 'y',
            inflating_resolution_slice = 256,
            inflating_resolution_mask = 256,
            smoothing_active = false,
            smoothing_filter = "laplacian",
            smoothing_iterations = 1
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a model asset.")
            }

            // payload
            const payload =  {
                centering: {
                    active: centering_active
                },
                reducing: {
                    active: reducing_active,
                    tolerance: reducing_tolerance
                },
                inflating: {
                    active: inflating_active,
                    direction: inflating_direction,
                    resolution_slice: inflating_resolution_slice,
                    resolution_mask: inflating_resolution_mask
                },
                smoothing: {
                    active: smoothing_active,
                    filter: smoothing_filter,
                    iterations: smoothing_iterations
                }
            }

            try 
            {
                // send API request to reduce polygons in model
                const response = await dispatch('requestApi', {
                    url: ROUTE_MODELS_MODEL_OPTIMIZE(asset.id),
                    method: REST_POST,
                    payload
                })

                // set asset file optimized from response
                asset.files['optimized'] = response.files['optimized']

                return response
            }
            catch(error)
            {
                throw error
            }
        },

        // models {model} thumbnail generate (post)
        async modelsModelThumbnailGeneratePost({ dispatch }, {
            asset
        })
        {
            // ensure asset parameter is provided
            if (asset === null || asset === undefined) {
                throw new Error("Please provide a model asset.")
            }

            try 
            {
                // send API request to reduce polygons in model
                const response = await dispatch('requestApi', {
                    url: ROUTE_MODELS_MODEL_THUMBNAIL_GENERATE(asset.id),
                    method: REST_POST
                })

                return response
            }
            catch(error)
            {
                throw error
            }
        },



        //// LOGGING
        
        // logging event (post)
        async loggingEventPost({ dispatch, rootGetters }, {
            name,
            description
        })
        {
            // ensure name parameter is provided
            if (name === null || name === undefined) {
                throw new Error("Please provide a name for the event.")
            }

            // ensure description parameter is provided
            if (description === null || description === undefined) {
                throw new Error("Please provide a description for the event.")
            }

            // app in debugging mode
            if (rootGetters['app/isDebugging'])
            {
                console.warn("event not send to server")
                console.log("name: " + name + "\ndescription: " + description)
                return
            }

            // payload
            const payload = {
                name,
                description,
                app: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion']
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_LOGGING_EVENT,
                method: REST_POST,
                payload
            })
        },

        // logging bug (post)
        async loggingBugPost({ dispatch, rootGetters }, {
            name,
            description
        })
        {
            // ensure name parameter is provided
            if (name === null || name === undefined) {
                throw new Error("Please provide a name for the bug.")
            }

            // ensure description parameter is provided
            if (description === null || description === undefined) {
                throw new Error("Please provide a description for the bug.")
            }

            // app in debugging mode
            if (rootGetters['app/isDebugging'])
            {
                console.warn("bug not send to server")
                console.log("name: " + name + "\ndescription: " + description)
                return
            }

            // payload
            const payload = {
                name,
                description,
                app: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion']
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_LOGGING_BUG,
                method: REST_POST,
                payload
            })
        },

        // logging feedback (post)
        async loggingFeedbackPost({ dispatch, rootGetters }, {
            topic,
            feedback,
            additional = null
        })
        {
            // ensure topic parameter is provided
            if (topic === null || topic === undefined) {
                throw new Error("Please provide a topic for the feedback.")
            }

            // ensure feedback parameter is provided
            if (feedback === null || feedback === undefined) {
                throw new Error("Please provide content for the feedback.")
            }

            // app in debugging mode
            if (rootGetters['app/isDebugging'])
            {
                console.warn("feedback not send to server")
                console.log(`topic: ${topic}\nfeedback: ${feedback}`)
                return
            }

            // payload
            const payload = {
                topic,
                feedback,
                additional, 
                app: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion']
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_LOGGING_FEEDBACK,
                method: REST_POST,
                payload
            })
        },

        // logging vote (get)
        async loggingVoteGet({ dispatch }, {
            topic
        })
        {
            // ensure topic parameter is provided
            if (topic === null || topic === undefined) {
                throw new Error("Please provide a topic for the vote.")
            }

            // payload
            const payload = {
                topic,
                app: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion']
            }
            
            try 
            {
                // send API request to retrieve vote
                const response = await dispatch('requestApi', {
                    url: ROUTE_LOGGING_VOTE,
                    method: REST_GET,
                    payload
                })

                // extract vote
                const voting = response.voting.toLowerCase()

                // commit response to vuex state
                commit('SET_VOTE', { key, value: voting === 'true' })

                return response
            }
            catch(error)
            {
                // user has not voted yet
                commit('SET_VOTE', { key, value: false })

                throw error
            }
        },

        // logging vote (put)
        async loggingVotePut({ getters, dispatch }, {
            topic, 
            vote
        })
        {
            // ensure topic parameter is provided
            if (topic === null || topic === undefined) {
                throw new Error("Please provide a topic for the vote.")
            }

            // toggle existing vote if left blank
            const voting = vote ? vote : !getters.getVote(topic)

            // payload
            const payload = {
                topic,
                voting, 
                app: rootGetters['app/getAppname'],
                version: rootGetters['app/getVersion']
            }

            try 
            {
                // send API request to retrieve vote
                const response = await dispatch('requestApi', {
                    url: ROUTE_LOGGING_VOTE,
                    method: REST_PUT,
                    payload
                })

                // commit response to vuex state
                commit('SET_VOTE', { key, value: voting })

                return response
            }
            catch(error)
            {
                throw error
            }
        },



        //// MONITORING
        
        // monitoring status (get)
        async monitoringStatusGet({ commit, dispatch })
        {
            try 
            {
                // send API request to retrieve status
                const response = await dispatch('requestApi', {
                    url: ROUTE_MONITORING_STATUS,
                    method: REST_GET
                })

                // extract status
                const status = response.status == 'up' ? SERVICE_STATUS_UP : SERVICE_STATUS_DOWN
                const queues = response.queues

                // commit status
                commit('SET_SERVICE', { status, queues })
                
                return response
            }
            catch(error)
            {
                // set status
                commit('SET_SERVICE', { status: SERVICE_STATUS_DOWN, queues: null })

                throw error
            }
        },



        //// ADMINISTRATION

        // administration activity users (get)
        async administrationActivityUsersGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_ACTIVITY_USERS,
                method: REST_GET,
                payload
            })
        },

        // administration activity assets (get)
        async administrationActivityAssetsGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_ACTIVITY_ASSETS,
                method: REST_GET,
                payload
            })
        },

        // administration logging events (get)
        async administrationLoggingEventsGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_LOGGING_EVENTS,
                method: REST_GET,
                payload
            })
        },

        // administration logging bugs (get)
        async administrationLoggingBugsGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_LOGGING_BUGS,
                method: REST_GET,
                payload
            })
        },

        //  administration logging feedback (get)
        async administrationLoggingFeedbackGet({ dispatch }, {
            count = 4
        })
        {
            // payload
            const payload = {
                count
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_LOGGING_FEEDBACK,
                method: REST_GET,
                payload
            })
        },

        // administration logging votes (get)
        async administrationLoggingVotesGet({ dispatch, rootGetters })
        {
            // payload
            const payload = {
                version: "0.4.0" //rootGetters['app/getVersion']
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_LOGGING_VOTES,
                method: REST_GET,
                payload
            })
        },

        // administration newsletters (get)
        async administrationNewslettersGet({ dispatch })
        {
            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_NEWSLETTERS,
                method: REST_GET
            })
        },

        // administration newsletters send (post)
        async administrationNewslettersSendPost({ dispatch }, {
            subject,
            body,
        })
        {
            // ensure subject parameter is provided
            if (subject === null || subject === undefined) {
                throw new Error("Please providde a subject for the newsletter.")
            }

            // ensure body parameter is provided
            if (body === null || body === undefined) {
                throw new Error("Please provide a body for the newsletter.")
            }

            // payload
            const payload = {
                subject,
                body
            }

            // send API request and return the promise
            return dispatch('requestApi', {
                url: ROUTE_ADMINISTRATION_NEWSLETTERS_SEND,
                method: REST_GET,
                payload
            })
        },



        //// UTILS

        // send a request to the API
        async requestApi({ dispatch, commit, rootGetters }, { 
            url, 
            method = REST_POST, 
            payload = null, 
            token = null
        }) 
        {
            return new Promise(async (resolve, reject) => {

                // retrieve token 
                if (token === null) token = rootGetters['app/getToken']
        
                try 
                {
                    // content of request
                    const content = {
                        method: method,
                        headers: {
                            'Content-Type': 'application/json',
                            // token only when specified
                            ...(token && { 'Authorization': 'Bearer ' + token }) 
                        }
                    }
        
                    // payload when post / put
                    if (method === REST_POST || method === REST_PUT) {
                        content.body = JSON.stringify(payload)
                    }
                    // payload when get
                    else if (method === REST_GET && payload && Object.keys(payload).length > 0) {
                        // serialize payload into query parameters
                        const queryParams = new URLSearchParams(payload).toString()
        
                        // append query parameters to URL
                        url += `?${queryParams}`
                    }
        
                    // await raw response
                    const rawResponse = await fetch(ROUTE_BASE + url, content)

                    // handle error response
                    if (!rawResponse.ok) 
                    {
                        let errorMessage = 'We had trouble processing your request.'
        
                        // attempt to parse the error response as JSON
                        try {
                            const errorResponse = await rawResponse.json()
                            if (errorResponse && typeof errorResponse.detail === 'string') {
                                errorMessage = errorResponse.detail
                            }
                        } catch (parseError) {}
        
                        // throw a new error with the detailed message
                        const error = new Error(errorMessage)
                        error.status = rawResponse.status
                        throw error
                    }

                    // await response (as json)
                    const response = await rawResponse.json()

                    // service seems to be running
                    commit('SET_SERVICE_STATUS', SERVICE_STATUS_UP)
        
                    resolve(response)
                } 
                catch (error) 
                {
                    // prepare error data
                    const errorCode = error.status || null
                    
                    // unauthorized access (invalid or outdated token)
                    if (errorCode === 401) {
                        // logout user
                        dispatch('app/logoutUser', {}, { root: true })
                    }
                    // service unavailable / encounters problems
                    else if (errorCode === 500) {
                        // change service status to not running
                        commit('SET_SERVICE', { status: SERVICE_STATUS_DOWN, queues: null })
                    }
        
                    reject(error)
                }
            })
        }
    }
}