// vuex.js state for Texturizer
import { extractExtension } from '@/utils/file.js'


// windows
const WINDOW_IMAGE = 'image'
const WINDOW_DEPTH = 'depth'
const WINDOW_LAYER = 'layer'
const WINDOW_COMPOSE = 'compose'
const WINDOW_EXPORT = 'export'
const WINDOW_DEFAULT = WINDOW_IMAGE


export const texturizer = {
    namespaced: true,
    state: {
        window: WINDOW_DEFAULT,
        image: {
            asset: null
        }
    },
    mutations: {
        SET_WINDOW(state, window) {
            state.window = window
        },
        SET_IMAGE_ASSET(state, asset) {
            state.image.asset = asset
        }
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowImage: () => WINDOW_IMAGE,
        keyWindowDepth: () => WINDOW_DEPTH,
        keyWindowLayer: () => WINDOW_LAYER,
        keyWindowCompose: () => WINDOW_COMPOSE,
        keyWindowExport: () => WINDOW_EXPORT,
        hasImageFileDefault: (state, getters) => getters.getImageFileDefault !== null,
        hasImageFileDepth: (state, getters) => getters.getImageFileDepth !== null,
        hasImageFileSegment: (state, getters) => getters.getImageFileSegment !== null,
        isWindowDefault: state => state.window === WINDOW_DEFAULT,
        isWindowImage: state => state.window === WINDOW_IMAGE,
        isWindowDepth: state => state.window === WINDOW_DEPTH,
        isWindowLayer: state => state.window === WINDOW_LAYER,
        isWindowCompose: state => state.window === WINDOW_COMPOSE,
        isWindowExport: state => state.window === WINDOW_EXPORT,
        isWindowImageDisabled: () => false,
        isWindowDepthDisabled: (state, getters) => !getters.hasImageFileDepth,
        isWindowLayerDisabled: (state, getters) => !getters.hasImageFileSegment,
        isWindowComposeDisabled: () => true,
        isWindowExportDisabled: () => true,
        getWindow: state => state.window,
        getImageAsset: state => state.image.asset,
        getImageFile: state => (fileKey) => {
            const asset = state.image.asset
            if (asset?.files[fileKey]) return asset.files[fileKey]
            return null
        },
        getImageFileDefault: (state, getters) => getters.getImageFile('default'),
        getImageFileDepth: (state, getters) => getters.getImageFile('depth'),
        getImageFileSegment: (state, getters) => getters.getImageFile('segment'),
        getImageRecents: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppTexturizer'],
                rootGetters['api/keyAssetTypeImage']
            )
            if (assets) 
            {
                assets = assets
                .sort((a, b) => b.created - a.created)
                return assets.slice(0, 3)
            } 
            return null
        },
        getImageExamples: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketchurizer'],
                rootGetters['api/keyAssetTypeLayer']
            )
            if (assets) {
                assets = assets.sort((a, b) => b.created - a.created)
                return assets.slice(0, 3)
            }
            return null
        },
    },
    actions: {
        setWindow({ commit }, window) {
            commit('SET_WINDOW', window)
        },
        setWindowToDefault({ commit }) {
            commit('SET_WINDOW', WINDOW_DEFAULT)
        },
        setWindowToImage({ commit }) {
            commit('SET_WINDOW', WINDOW_IMAGE)
        },
        setWindowToDepth({ commit }) {
            commit('SET_WINDOW', WINDOW_DEPTH)
        },
        setWindowToLayer({ commit }) {
            commit('SET_WINDOW', WINDOW_LAYER)
        },
        setWindowToCompose({ commit }) {
            commit('SET_WINDOW', WINDOW_COMPOSE)
        },
        setWindowToExport({ commit }) {
            commit('SET_WINDOW', WINDOW_EXPORT)
        },
        clearImageAsset({ commit }) {
            commit('SET_IMAGE_ASSET', null)
        },
        async setImageAsset({ commit, dispatch, rootGetters }, asset) 
        {
            commit('SET_IMAGE_ASSET', asset)

            try
            {
                // if asset is empty: nothing to do
                if (asset === null) return null

                // if asset is not unrequested: nothing to do
                const statusUnrequested = rootGetters['api/keyAssetStatusUnrequested']
                if (asset.files['default'].status !== statusUnrequested) return null

                // retrieve asset
                const response = await dispatch('retrieveImageFile', 'default')

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        async importImage({ commit, dispatch, rootGetters }, { file, base64 }) 
        {
            // ensure file parameter is provided
            if (file === null || file === undefined) {
                throw new Error("Please provide a file to import.")
            }

            // ensure base64 parameter is provided
            if (base64 === null || base64 === undefined) {
                throw new Error("Please provide the file content to import.")
            }      
            
            try
            {
                //// PREPROCESS

                // extract extension from file name
                let extension = extractExtension(file.name)

                // supported formats
                const supported = ['png', 'jpg']

                // if format is not supported
                if (!supported.includes(extension)) {
                    throw new Error("The file format is not supported.")
                }

                // change generic mime type according to extension
                base64 = base64.replace('application/octet-stream', 'image/' + extension)

                //// IMAGE DEFAULT

                // call route to post the image asset
                const asset = await dispatch('api/assetsPost', {
                    type: rootGetters['api/keyAssetTypeImage'],
                    file: base64
                }, { root: true })

                // update image asset
                const statusRetrieved = rootGetters['api/keyAssetStatusRetrieved']
                asset.files['default'].data = base64
                asset.files['default'].status = statusRetrieved

                // set image asset
                dispatch('setImageAsset', asset)

                await dispatch('api/assetsAssetStatusGet', {
                    assetId: asset.id
                }, { root: true })

                //
                // TODO thumbnail? via route?
                //

                //// IMAGE DEPTH
                
                await dispatch('generateImageFileDepth')
                //await dispatch('retrieveImageFile', 'depth')

                //// IMAGE SEGMENT

                //await dispatch('generateImageFileSegment')
                //await dispatch('retrieveImageSegment', 'segment')

                return asset
            }
            catch(error)
            {
                throw error
            }
        },
        async retrieveImageFile({ getters, dispatch, rootGetters }, fileKey) 
        {
            try 
            {
                // get image asset
                const asset = getters.getImageAsset

                // ensure image asset exists
                if (asset === null || asset === undefined) {
                    throw new Error("Please provide an image asset.")
                }

                // call route to retrieve depth file
                const response = await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                    key: fileKey
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        async generateImageFileDepth({ getters, dispatch })
        {
            try
            {
                // get image asset
                const asset = getters.getImageAsset

                // ensure image asset exists
                if (asset === null || asset === undefined) {
                    throw new Error("Please provide an image asset.")
                }

                // call route to generate a depth image
                const response = await dispatch('api/imagesImageDepthGeneratePost', {
                    asset
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        async generateImageFileSegment({ getters, dispatch })
        {
            try
            {
                // get image asset
                const asset = getters.getImageAsset

                // ensure image asset exists
                if (asset === null || asset === undefined) {
                    throw new Error("Please provide an image asset.")
                }

                // call route to generate a depth image
                const response = await dispatch('api/imagesImageSegmentGeneratePost', {
                    asset
                }, { root: true })

                return response
            }
            catch(error)
            {
                throw error
            }
        }
    }
}