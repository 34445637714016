// vuex.js state for Boxurizer
import * as THREE from 'three'
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter.js'

import { Container } from '@/classes/box/Container.js'
import { Infill } from '@/classes/box/Infill.js'

import { centerGeometry, normalizeGeometry, divideGeometry, repairTextureMapping } from '@/utils/geometry.js'
import { extractExtension, downloadFile } from '@/utils/file.js'
import { loadMesh } from '@/utils/loader.js'
import { round } from '@/utils/math.js'


const WINDOW_ITEM = 'item'
const WINDOW_CONTAINER = 'container'
const WINDOW_INFILL = 'infill'
const WINDOW_RENDER = 'render'
const WINDOW_EXPORT = 'export'
const WINDOW_DEFAULT = WINDOW_ITEM

const DIRECTION_BIRD = 6
const DIRECTION_RIGHT = 2
const DIRECTION_LEFT = 3
const DIRECTION_TOP = 4
const DIRECTION_BOTTOM = 5
const DIRECTION_FRONT = 0
const DIRECTION_BACK = 1

const CONVERSION_SIZE = 10.0

const CAMERA_PERSPECTIVE_DEFAULT = DIRECTION_FRONT

const MODEL_TYPE_ITEM = 'item'
const MODEL_TYPE_CONTAINER = 'container'
const MODEL_TYPE_INFILL = 'infill'

const CONTAINER_SPACING_MODE_AUTOMATIC = 'automatic'
const CONTAINER_SPACING_MODE_MANUAL = 'manual'
const CONTAINER_SPACING_MODE_DEFAULT = CONTAINER_SPACING_MODE_AUTOMATIC

const CONTAINER_SPACING_MINIMUM_DEFAULT = 20
const CONTAINER_SPACING_FIXED_DEFAULT = 15

const INFILL_TOLERANCE_DEFAULT = 1

const RENDER_MODE_TEXTURED = 'textured'
const RENDER_MODE_SOLID = 'solid'
const RENDER_MODE_TRANSPARENT = 'transparent'
const RENDER_MODE_WIREFRAME = 'wireframe'
const RENDER_MODE_DEFAULT = RENDER_MODE_TEXTURED

const RENDER_FORMAT_PNG = 'png'
const RENDER_FORMAT_JPG = 'jpg'
const RENDER_FORMAT_DEFAULT = RENDER_FORMAT_PNG

const EXPORT_SCALING_DEFAULT = 103

const EXPORT_TYPE_POSITIVE = 'positive'
const EXPORT_TYPE_NEGATIVE = 'negative'

const EXPORT_FORMAT_STEP = 'step'
const EXPORT_FORMAT_STL = 'stl'
const EXPORT_FORMAT_DEFAULT = EXPORT_FORMAT_STL


function newSize() {
    return {
        width: 0,
        height: 0,
        depth: 0
    }
}

function convertSize(direction, size) {
    let newSize = null
    switch (direction) {
        case DIRECTION_RIGHT:
        case DIRECTION_LEFT:
            newSize = {
                width: size.depth,
                height: size.height,
                depth: size.width
            }
            break
        case DIRECTION_TOP:
        case DIRECTION_BOTTOM:
            newSize = {
                width: size.width,
                height: size.height,
                depth: size.depth
            }
            break
        case DIRECTION_FRONT:
        case DIRECTION_BACK:
            newSize = {
                width: size.width,
                height: size.depth,
                depth: size.height
            }
            break
    }
    return newSize
}


export const boxurizer = {
    namespaced: true,
    state: {
        window: WINDOW_DEFAULT,
        camera: {
            perspective: CAMERA_PERSPECTIVE_DEFAULT
        },
        item: {
            visible: true,
            asset: null,
            mesh: null,
            center: [0, 0, 0],
            size: {
                original: newSize(),
                current: newSize()
            },
            direction: DIRECTION_TOP,
            optimized: null,
            debugging: false
        },
        container: {
            visible: true,
            slots: {
                columns: 1,
                stacks: 1,
                rows: 1
            },
            options: [],
            selection: null,
            spacing: {
                mode: CONTAINER_SPACING_MODE_DEFAULT,
                minimum: CONTAINER_SPACING_MINIMUM_DEFAULT,
                fixed: CONTAINER_SPACING_FIXED_DEFAULT
            },
            sides: {
                highlight: null,
                selection: {}
            }
        },
        infill: {
            visible: true,
            list: [],
            selection: null,
            asset: null,
            mesh: null,
            tolerance: INFILL_TOLERANCE_DEFAULT,
            optimized: false,
            calculated: false
        },
        render: {
            mode: RENDER_MODE_DEFAULT,
            format: RENDER_FORMAT_DEFAULT
        },
        export: {
            scaling: EXPORT_SCALING_DEFAULT,
            type: EXPORT_TYPE_POSITIVE,
            format: EXPORT_FORMAT_DEFAULT
        }
    },
    mutations: {
        SET_WINDOW(state, window) {
            state.window = window
        },

        SET_CAMERA_PERSPECTIVE(state, perspective) {
            state.camera.perspective = perspective
        },

        RESET_ITEM(state) {
            state.item.visible = true
            state.item.asset = null
            state.item.mesh = null
            state.item.center = [ 0, 0, 0 ]
            state.item.size = {
                original: newSize(),
                current: newSize()
            }
            state.item.direction = DIRECTION_TOP
            state.item.optimized = null
            state.item.debugging = false
        },
        SET_ITEM_VISIBLE(state, visible) {
            state.item.visible = visible
        },
        SET_ITEM_ASSET(state, asset) {
            state.item.asset = asset
        },
        SET_ITEM_MESH(state, mesh) {
            state.item.mesh = mesh
        },
        SET_ITEM_CENTER(state, { x, y, z })
        {
            state.item.center[0] = x
            state.item.center[1] = y
            state.item.center[2] = z
        },
        SET_ITEM_SIZE_ORIGINAL(state, { width, height, depth }) {
            state.item.size.original.width = width
            state.item.size.original.height = height
            state.item.size.original.depth = depth
        },
        SET_ITEM_SIZE_CURRENT(state, { width, height, depth }) 
        {
            const newSize = {
                width: round(width, 2),
                height: round(height, 2),
                depth: round(depth, 2)
            }

            state.item.size.current = newSize
        },
        SET_ITEM_SIZE_CURRENT_CONVERTED(state, { width, height, depth }) 
        {
            const newSize = convertSize(state.item.direction, {
                width: round(width, 2),
                height: round(height, 2),
                depth: round(depth, 2)
            })

            state.item.size.current = newSize
        },
        SET_ITEM_DIRECTION(state, direction) 
        {
            state.item.direction = direction
        },
        SET_ITEM_OPTIMIZED(state, optimized)
        {
            state.item.optimized = optimized
        },
        SET_ITEM_DEBUGGING(state, debugging)
        {
            state.item.debugging = debugging
        },

        RESET_CONTAINER(state) {
            state.container.visible = true
            state.container.slots.rows = 1
            state.container.slots.stacks = 1
            state.container.slots.columns = 1
            state.container.selection = null
            state.container.spacing.mode = CONTAINER_SPACING_MODE_DEFAULT
            state.container.spacing.minimum = CONTAINER_SPACING_MINIMUM_DEFAULT
            state.container.spacing.fixed = CONTAINER_SPACING_FIXED_DEFAULT
            state.container.sides.highlight = null
            state.container.sides.selection = {}
        },
        RESET_CONTAINER_OPTIONS(state) {
            state.container.options = []
        },
        SET_CONTAINER_VISIBLE(state, visible) {
            state.container.visible = visible
        },
        SET_CONTAINER_COLUMNS(state, columns) {
            state.container.slots.columns = columns
        },
        SET_CONTAINER_STACKS(state, stacks) {
            state.container.slots.stacks = stacks
        },
        SET_CONTAINER_ROWS(state, rows) {
            state.container.slots.rows = rows
        },
        SET_CONTAINER_OPTIONS(state, options) {
            state.container.options = options

            const selectionExists = state.container.options.some(
                (option) => option.id === state.container.selection
            )
            if (!selectionExists) state.container.selection = null
        },
        ADD_CONTAINER_OPTION(state, container) {
            state.container.options.push(container)
        },
        REMOVE_CONTAINER_OPTION(state, containerId) {
            state.container.options = state.container.options.filter(
                (option) => option.id !== containerId
            )
        },
        SET_CONTAINER_SELECTION(state, selection) {
            state.container.selection = selection
        },
        SET_CONTAINER_SPACING_MODE(state, mode) {
            state.container.spacing.mode = mode
        },
        SET_CONTAINER_SPACING_MINIMUM(state, minimum) {
            state.container.spacing.minimum = minimum
        },
        SET_CONTAINER_SPACING_FIXED(state, fixed) {
            state.container.spacing.fixed = fixed
        },
        SET_CONTAINER_SIDES_HIGHLIGHT(state, highlight) {
            state.container.sides.highlight = highlight
        },
        SET_CONTAINER_SIDES_SELECTION(state, selection) {
            state.container.sides.selection = selection
        },

        RESET_INFILL(state) {
            state.infill.visible = true
            state.infill.list = []
            state.infill.selection = null
            state.infill.asset = null
            state.infill.mesh = null
            state.infill.calculated = false
            state.infill.tolerance = INFILL_TOLERANCE_DEFAULT
            state.infill.optimized = false
        },
        ADD_INFILL(state, infill) {
            state.infill.list.push(infill)
        },
        REMOVE_INFILL(state, infillId) {
            state.infill.list = state.infill.list.filter(infill => infill.id !== infillId)
            if (state.infill.selection == infillId) state.infill.selection = null
        },
        SET_INFILL_VISIBLE(state, visible) {
            state.infill.visible = visible
        },
        SET_INFILL_LIST(state, list) {
            state.infill.list = list
            const hasSelection = state.infill.list.some(infill => infill.id === state.infill.selection)
            if (!hasSelection) state.infill.selection = null
        },      
        SET_INFILL_SELECTION(state, selection) {
            state.infill.selection = selection
        },
        SET_INFILL_ASSET(state, asset) {
            state.infill.asset = asset
        },
        SET_INFILL_MESH(state, mesh) {
            state.infill.mesh = mesh
        },
        SET_INFILL_CALCULATED(state, calculated) {
            state.infill.calculated = calculated
        },
        SET_INFILL_TOLERANCE(state, tolerance) {
            state.infill.tolerance = tolerance
        },
        SET_INFILL_OPTIMIZED(state, optimized) {
            state.infill.optimized = optimized
        },

        SET_RENDER_MODE(state, mode) {
            state.render.mode = mode
        },
        SET_RENDER_FORMAT(state, format) {
            state.render.format = format
        },

        SET_EXPORT_SCALING(state, scaling) {
            state.export.scaling = scaling
        },
        SET_EXPORT_TYPE(state, type) {
            state.export.type = type
        },
        SET_EXPORT_FORMAT(state, format) {
            state.export.format = format
        }
    },
    getters: {
        keyWindowDefault: () => WINDOW_DEFAULT,
        keyWindowItem: () => WINDOW_ITEM,
        keyWindowContainer: () => WINDOW_CONTAINER,
        keyWindowInfill: () => WINDOW_INFILL,
        keyWindowRender: () => WINDOW_RENDER,
        keyWindowExport: () => WINDOW_EXPORT,
        keyDirectionBird: () => DIRECTION_BIRD,
        keyDirectionRight: () => DIRECTION_RIGHT,
        keyDirectionLeft: () => DIRECTION_LEFT,
        keyDirectionTop: () => DIRECTION_TOP,
        keyDirectionBottom: () => DIRECTION_BOTTOM,
        keyDirectionFront: () => DIRECTION_FRONT,
        keyDirectionBack: () => DIRECTION_BACK,
        keyConversionSize: () => CONVERSION_SIZE,
        keyCameraPerspectiveDefault: () => CAMERA_PERSPECTIVE_DEFAULT,
        keyModelTypeItem: () => MODEL_TYPE_ITEM,
        keyModelTypeContainer: () => MODEL_TYPE_CONTAINER,
        keyModelTypeInfill: () => MODEL_TYPE_INFILL,
        keyContainerSpacingModeDefault: () => CONTAINER_SPACING_MODE_DEFAULT,
        keyContainerSpacingModeAutomatic: () => CONTAINER_SPACING_MODE_AUTOMATIC,
        keyContainerSpacingModeManual: () => CONTAINER_SPACING_MODE_MANUAL,
        keyContainerSpacingMinimumDefault: () => CONTAINER_SPACING_MINIMUM_DEFAULT,
        keyContainerSpacingFixedDefault: () => CONTAINER_SPACING_FIXED_DEFAULT,
        keyInfillToleranceDefault: () => INFILL_TOLERANCE_DEFAULT,
        keyRenderModeDefault: () => RENDER_MODE_DEFAULT,
        keyRenderModeTextured: () => RENDER_MODE_TEXTURED,
        keyRenderModeSolid: () => RENDER_MODE_SOLID,
        keyRenderModeTransparent: () => RENDER_MODE_TRANSPARENT,
        keyRenderModeWireframe: () => RENDER_MODE_WIREFRAME,
        keyRenderFormatDefault: () => RENDER_FORMAT_DEFAULT,
        keyRenderFormatPNG: () => RENDER_FORMAT_PNG,
        keyRenderFormatJPG: () => RENDER_FORMAT_JPG,
        keyExportTypePositive: () => EXPORT_TYPE_POSITIVE,
        keyExportTypeNegative: () => EXPORT_TYPE_NEGATIVE,
        keyExportFormatDefault: () => EXPORT_FORMAT_DEFAULT,
        keyExportFormatSTEP: () => EXPORT_FORMAT_STEP,
        keyExportFormatSTL: () => EXPORT_FORMAT_STL,
        isWindowDefault: state => state.window === WINDOW_DEFAULT,
        isWindowItem: state => state.window === WINDOW_ITEM,
        isWindowContainer: state => state.window === WINDOW_CONTAINER,
        isWindowInfill: state => state.window === WINDOW_INFILL,
        isWindowRender: state => state.window === WINDOW_RENDER,
        isWindowExport: state => state.window === WINDOW_EXPORT,
        isCameraPerspectiveDefault: state => state.camera.perspective === CAMERA_PERSPECTIVE_DEFAULT, 
        isCameraPerspectiveBird: state => state.camera.perspective === DIRECTION_BIRD, 
        isCameraPerspectiveRight: state => state.camera.perspective === DIRECTION_RIGHT,
        isCameraPerspectiveLeft: state => state.camera.perspective === DIRECTION_LEFT,
        isCameraPerspectiveTop: state => state.camera.perspective === DIRECTION_TOP,
        isCameraPerspectiveBottom: state => state.camera.perspective === DIRECTION_BOTTOM,
        isCameraPerspectiveFront: state => state.camera.perspective === DIRECTION_FRONT,
        isCameraPerspectiveBack: state => state.camera.perspective === DIRECTION_BACK,
        isItemVisible: state => state.item.visible,
        isItemHuge: (state, getters) => getters.getItemSizeCurrentMagnitude > 1000,
        isItemDebugging: state => state.item.debugging,
        isContainerVisible: state => state.container.visible,
        isContainerUndersized: (state, getters) => {
            const container = getters.getContainerOptionSelected
            if (container === null) return false
            const extent = getters.getContainerInfillMinimumExtent
            if (extent.width > container.dimensions.width) return true
            if (extent.height > container.dimensions.height) return true
            if (extent.depth > container.dimensions.depth) return true
            return false
        },
        isContainerColumnsOversized: (state, getters) => {
            const container = getters.getContainerOptionSelected
            if (container === null) return false
            const extent = getters.getContainerInfillMinimumExtent
            if (extent.width > container.dimensions.width) return true
            return false
        },
        isContainerStacksOversized: (state, getters) => {
            const container = getters.getContainerOptionSelected
            if (container === null) return false
            const extent = getters.getContainerInfillMinimumExtent
            if (extent.height > container.dimensions.height) return true
            return false
        },
        isContainerRowsOversized: (state, getters) => {
            const container = getters.getContainerOptionSelected
            if (container === null) return false
            const extent = getters.getContainerInfillMinimumExtent
            if (extent.depth > container.dimensions.depth) return true
            return false
        },
        isContainerSpacingAutomatic: state => state.container.spacing.mode === CONTAINER_SPACING_MODE_AUTOMATIC,
        isContainerSpacingManual: state => state.container.spacing.mode === CONTAINER_SPACING_MODE_MANUAL,
        isInfillVisible: state => state.infill.visible,
        isInfillSelected: state => state.infill.selection !== null,
        isInfillCalculated: state => state.infill.calculated,
        isInfillOptimized: state => state.infill.optimized,
        isRenderModeDefault: state => state.render.mode === RENDER_MODE_DEFAULT,
        isRenderModeTextured: state => state.render.mode === RENDER_MODE_TEXTURED,
        isRenderModeSolid: state => state.render.mode === RENDER_MODE_SOLID,
        isRenderModeTransparent: state => state.render.mode === RENDER_MODE_TRANSPARENT,
        isRenderModeWireframe: state => state.render.mode === RENDER_MODE_WIREFRAME,
        hasItem: state => state.item.mesh !== null,
        hasItemAsset: state => state.item.asset !== null,
        /*
        hasItemAssetDefault: (state, getters, rootState, rootGetters) => {
            const fileDefault = getters.getItemAssetDefault
            if (fileDefault === null || fileDefault === undefined) return false
            if (fileDefault.status !== rootGetters['api/keyAssetStatusRetrieved']) return false
            if (fileDefault.data === null || fileDefault.data === undefined || fileDefault.data === "") return false
            return true
        },
        hasItemAssetOptimize: (state, getters, rootState, rootGetters) => {
            const fileOptimize = getters.getItemAssetOptimize
            console.log(fileOptimize)
            if (fileOptimize === null || fileOptimize === undefined) return false
            if (fileOptimize.status !== rootGetters['api/keyAssetStatusRetrieved']) return false
            if (fileOptimize.data === null || fileOptimize.data === undefined || fileOptimize.data === "") return false
            return true
        },*/
        hasItemOptimized: state => state.item.optimized != null,
        hasItemWarning: (state, getters) => {
            if (getters.isItemHuge) return true
            return false
        },
        hasContainer: state => state.container.selection !== null,
        hasContainerWarning: (state, getters) => {
            if (getters.isContainerUndersized) return true
            return false
        },
        hasInfill: state => state.infill.list.length > 0,
        hasInfillAsset: state => state.infill.asset !== null,
        hasInfillWarning: (state, getters) => {
            return false
        },
        hasWarning: (state, getters) => {
            if (getters.isWindowItem) return getters.hasItemWarning
            if (getters.isWindowContainer) return getters.hasContainerWarning
            if (getters.isWindowInfill) return getters.hasInfillWarning
            return false
        },

        getDirectionLabel: state => (direction) => {
            if (direction === DIRECTION_RIGHT) return 'right'
            if (direction === DIRECTION_LEFT) return 'left'
            if (direction === DIRECTION_TOP) return 'top'
            if (direction === DIRECTION_BOTTOM) return 'bottom'
            if (direction === DIRECTION_FRONT) return 'front'
            if (direction === DIRECTION_BACK) return 'back'
            if (direction === DIRECTION_BIRD) return 'bird'
            return ''
        },
        getActiveWindow: state => state.window,
        getCameraPerspective: state => state.camera.perspective,

        getItemAsset: state => state.item.asset,
        //getItemAssetDefault: state => state.item.asset.files['default'],
        //getItemAssetOptimize: state => state.item.asset.files['optimize'],
        getItemMesh: state => state.item.mesh,
        getItemCenter: state => state.item.center,
        getItemSizeOriginal: state => state.item.size.original,
        getItemSizeCurrent: state => state.item.size.current,
        getItemSizeCurrentConverted: state => {
            return convertSize(state.item.direction, state.item.size.current)
        },
        getItemSizeCurrentMagnitude: state => {
            return Math.max(
                state.item.size.current.width, 
                state.item.size.current.height,
                state.item.size.current.depth
            )
        },
        getItemDirection: state => state.item.direction,
        getItemDirectionEuler: state => {
            switch(state.item.direction) 
            {
                case DIRECTION_RIGHT:
                    return [0, -90, 0]
                case DIRECTION_LEFT:
                    return [0,  90, 0]
                case DIRECTION_TOP:
                    // default
                    return [0, 0, 0]
                case DIRECTION_BOTTOM:
                    return [0, 0, 180]
                case DIRECTION_FRONT:
                    return [-90, 0, 0]
                case DIRECTION_BACK:
                    return [ 90, 0, 0]
            }
            return [0, 0, 0]
        },
        getItemOptimized: state => state.item.optimized,
        getItemRecents: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppBoxurizer'],
                rootGetters['api/keyAssetTypeModel']
            )
            if (assets) 
            {
                assets = assets
                .filter(asset => asset.additional?.type === MODEL_TYPE_ITEM)
                .sort((a, b) => b.created - a.created)
                return assets.slice(0, 3)
            } 
            return null
        },
        getItemExamples: (state, getters, rootState, rootGetters) => {
            let assets = rootGetters['api/getFilteredAssets'](
                rootGetters['app/keyAppSketchurizer'],
                rootGetters['api/keyAssetTypeModel']
            )
            if (assets) {
                assets = assets.sort((a, b) => b.created - a.created)
                return assets.slice(0, 3)
            }
            return null
        },

        getContainerColumns: state => state.container.slots.columns,
        getContainerStacks: state => state.container.slots.stacks,
        getContainerRows: state => state.container.slots.rows,
        getContainerItemCount: state => {
            return state.container.slots.columns *
                state.container.slots.stacks *
                state.container.slots.rows
        },
        getContainerOptions: state => state.container.options,
        getContainerOptionSelected: state => { 
            if (state.container.selection === null) return null
            return Object.values(state.container.options).find(
                option => option.id === state.container.selection
            ) || null
        },
        getContainerSelection: state => state.container.selection,
        getContainerSizeMagnitude: (state, getters) => {
            const container = getters.getContainerOptionSelected
            if (container === null) return 0
            return Math.max(
                container.dimensions.width, 
                container.dimensions.height,
                container.dimensions.depth
            )
        },
        getContainerSpacing: (state, getters) => {
            if (getters.isContainerSpacingAutomatic)
            {
                return getters.getContainerSpacingAutomatic
            }
            return getters.getContainerSpacingManual
        },
        getContainerSpacingAutomatic: (state, getters) => {

            // get minimum spacing
            const minimum = state.container.spacing.minimum

            // get selected container option
            const selectedOption = getters.getContainerOptionSelected

            // if option selected
            if (selectedOption)
            {
                // get item dimensions
                const itemSize = getters.getItemSizeCurrentConverted

                // get columns and rows
                const columns = getters.getContainerColumns
                const stacks = getters.getContainerStacks
                const rows = getters.getContainerRows
                
                // calculate spacing
                let width = (selectedOption.dimensions.width - (itemSize.width * columns)) / (columns + 1)
                let height = (selectedOption.dimensions.height - (itemSize.height * stacks)) / (stacks + 1)
                let depth = (selectedOption.dimensions.depth - (itemSize.depth * rows)) / (rows + 1)

                // ensure spacing is at least minimum
                if (width < minimum) width = minimum
                if (height < minimum) height = minimum
                if (depth < minimum) depth = minimum

                return { width, height, depth }
            }
            else
            {
                return {
                    width: minimum,
                    height: minimum,
                    depth: minimum
                }
            }
        },
        getContainerSpacingManual: (state, getters) => {
            const fixed = state.container.spacing.fixed
            return {
                width: fixed,
                height: fixed,
                depth: fixed
            }
        },
        getContainerSpacingMode: state => state.container.spacing.mode,
        getContainerSpacingMinimum: state => state.container.spacing.minimum,
        getContainerSpacingFixed: state => state.container.spacing.fixed,
        getContainerInfillMinimumExtent: (state, getters) => {
            let spacing = getters.getContainerSpacingMinimum 
            if (getters.isContainerSpacingManual) spacing = getters.getContainerSpacingFixed
            return getters.getContainerInfillExtent({
                width: spacing,
                height: spacing,
                depth: spacing
            })
        },
        getContainerInfillCurrentExtent: (state, getters) => {
            const spacing = getters.getContainerSpacing
            return getters.getContainerInfillExtent(spacing)
        },
        getContainerInfillExtent: (state, getters) => (spacing) => {
            // get needed state variables
            const size = getters.getItemSizeCurrentConverted
            const columns = getters.getContainerColumns
            const stacks = getters.getContainerStacks
            const rows = getters.getContainerRows

            // calculate total width and height to center items around (0, 0, 0)
            const width = (columns + 1) * spacing.width + columns * size.width
            const height = (stacks + 1) * spacing.height + stacks * size.height
            const depth = (rows + 1) * spacing.depth + rows * size.depth

            return {
                width,
                height,
                depth
            }
        },
        getContainerSlots: (state, getters) => {

            const slots = []

            // get needed state variables
            const size = getters.getItemSizeCurrentConverted
            const columns = getters.getContainerColumns
            const rows = getters.getContainerRows
            const spacing = getters.getContainerSpacing
            const extent = getters.getContainerInfillCurrentExtent

            // iterate over columns
            for (let column = 0; column < columns; column++) 
            {
                // iterate over rows
                for (let row = 0; row < rows; row++) 
                {
                    // calculate the position to center items around (0, 0, 0)
                    const x = 
                        column * size.width +
                        (column + 1) * spacing.width - 
                        extent.width / 2 + 
                        size.width / 2
                    const z = 
                        row * size.depth + 
                        (row + 1) * spacing.depth - 
                        extent.depth / 2 + 
                        size.depth / 2

                    // add current item position as slot
                    slots.push({
                        x, 
                        y: 0, 
                        z
                    })
                }
            }

            return slots
        },
        getContainerSidesHighlight: state => state.container.sides.highlight,
        getContainerSidesSelection: state => state.container.sides.selection,

        getInfillList: state => state.infill.list,
        getInfillCount: state => state.infill.list.length,
        getInfillSelection: state => state.infill.selection,
        getInfillSelected: state => {
            if (state.infill.selection === null) return null
            return state.infill.list.find(
                infill => infill.id === state.infill.selection
            ) || null
        },
        getInfillAttributes: (state, getters) => (infill) => {

            const id = infill.id

            const base = infill.dimensions.base / CONVERSION_SIZE
            const extent = infill.dimensions.height / CONVERSION_SIZE

            let { width, height, depth } = convertSize(infill.side, {
                width: base,
                height: extent,
                depth: base
            })

            const position = infill.position

            const option = getters.getContainerOptionSelected
            const limit = [
                option.dimensions.width / CONVERSION_SIZE, 
                option.dimensions.height / CONVERSION_SIZE, 
                option.dimensions.depth / CONVERSION_SIZE]

            const edge = infill.radii.edge / CONVERSION_SIZE
            const corner = infill.radii.corner / CONVERSION_SIZE

            return {    
                id, 
                width,
                height,
                depth,
                position,
                limit,
                edge,
                corner
            }
        },
        getInfillAsset: state => state.infill.asset,
        getInfillMesh: state => state.infill.mesh,
        getInfillTolerance: state => state.infill.tolerance,

        getRenderMode: state => state.render.mode,
        getRenderFormat: state => state.render.format,
        getRenderFormats: () => [
            { id: 0, title: RENDER_FORMAT_PNG}, 
            { id: 1, title: RENDER_FORMAT_JPG}
        ],
        getExportScaling: state => state.export.scaling,
        getExportType: state => state.export.type,
        getExportFormat: state => state.export.format,
        getExportFormats: () => [
            //{ id: 0, title: EXPORT_FORMAT_STEP },
            { id: 0, title: EXPORT_FORMAT_STL }
        ]
    },
    actions: {
        initializeBoxurizer({ dispatch }) {
            dispatch('fetchAppAdditional')
        },

        async fetchAppAdditional({ commit, dispatch }) {
            try
            {
                const response = await dispatch('api/appsAppAdditionalGet', 
                    null, { root: true })

                for (const key in response.containers) 
                {
                    const option = response.containers[key]
                    const container = new Container(
                        option.label,
                        option.width, option.height, option.depth
                    )
                    commit('ADD_CONTAINER_OPTION', container)
                }

                return response
            }
            catch(error)
            {
                throw error
            }
        },
        async updateAppAdditional({ getters, commit, dispatch }) 
        {
            // retrieve all states that are stored in additional
            const options = getters.getContainerOptions
            const containers = []
            for (const option of options) {
                containers.push({
                    label: option.label,
                    width: option.dimensions.width,
                    height: option.dimensions.height,
                    depth: option.dimensions.depth
                })
            }

            // call route to put app additional
            return dispatch('api/appsAppAdditionalPut', {
                additional: {
                    containers
                }
            }, { root: true })
        },

        setActiveWindow({ commit }, window) {
            commit('SET_WINDOW', window)
        },
        setActiveWindowToDefault({ commit }) {
            commit('SET_WINDOW', WINDOW_DEFAULT)
        },
        setActiveWindowToItem({ commit }) {
            commit('SET_WINDOW', WINDOW_ITEM)
        },
        setActiveWindowToContainer({ commit }) {
            commit('SET_WINDOW', WINDOW_CONTAINER)
        },
        setActiveWindowToInfill({ commit }) {
            commit('SET_WINDOW', WINDOW_INFILL)
        },
        setActiveWindowToRender({ commit }) {
            commit('SET_WINDOW', WINDOW_RENDER)
        },
        setActiveWindowToExport({ commit }) {
            commit('SET_WINDOW', WINDOW_EXPORT)
        },

        setCameraPerspective({ commit }, perspective) {
            commit('SET_CAMERA_PERSPECTIVE', perspective)
        },
        setCameraPerspectiveToDefault({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', CAMERA_PERSPECTIVE_DEFAULT)
        },
        setCameraPerspectiveToBird({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_BIRD)
        },
        setCameraPerspectiveToRight({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_RIGHT)
        },
        setCameraPerspectiveToLeft({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_LEFT)
        },
        setCameraPerspectiveToTop({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_TOP)
        },
        setCameraPerspectiveToBottom({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_BOTTOM)
        },
        setCameraPerspectiveToFront({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_FRONT)
        },
        setCameraPerspectiveToBack({ commit }) {
            commit('SET_CAMERA_PERSPECTIVE', DIRECTION_BACK)
        },

        clearItem({ commit }) {
            commit('RESET_INFILL')
            commit('RESET_CONTAINER')
            commit('RESET_ITEM')
        },
        async importItem({ getters, commit, dispatch, rootGetters }, { file, base64 }) 
        {
            // ensure file parameter is provided
            if (file === null || file === undefined) {
                throw new Error("Please provide a file to import.")
            }

            // ensure base64 parameter is provided
            if (base64 === null || base64 === undefined) {
                throw new Error("Please provide the file content to import.")
            }

            try
            {
                // clear existing item
                dispatch('clearItem')

                // variable to store item asset
                let asset = null

                // extract extension from file name
                let extension = extractExtension(file.name)

                // supported formats
                const supported = ['glb', 'gltf', 'stp', 'step', 'stl', 'obj', 'ply', 'fbx', 'filmbox']

                // if format is not supported
                if (!supported.includes(extension)) {
                    throw new Error("The file format is not supported.")
                }

                // set correct mime types
                if (extension === 'glb' || extension === 'gltf') extension = 'gltf-binary'
                if (extension === 'step') extension = 'stp'
                if (extension === 'filmbox') extension = 'fbx'

                // change generic mime type according to extension
                base64 = base64.replace('application/octet-stream', 'model/' + extension)

                // specify model type (item)
                const additional = { 
                    type: MODEL_TYPE_ITEM
                }

                // if gltf, we can directly upload the item
                if (extension === 'gltf-binary')
                {
                    // call route to post the model asset
                    asset = await dispatch('api/assetsPost', {
                        type: rootGetters['api/keyAssetTypeModel'],
                        file: base64,
                        additional
                    }, { root: true })

                    // no converting needed, so the base64 is the model file
                    asset.files['default'].data = base64
                    asset.files['default'].status = rootGetters['api/keyAssetStatusRetrieved']

                    // flag thumbnail as unrequested
                    asset.thumbnail.status = rootGetters['api/keyAssetStatusUnrequested']

                    // call route to check if the asset is ready
                    await dispatch('api/assetsAssetStatusGet', {
                        assetId: asset.id
                    }, { root: true })

                    // call route to generate thumbnail
                    await dispatch('api/modelsModelThumbnailGeneratePost', {
                        asset
                    }, { root: true })
                }
                // else, we need to convert it
                else
                {
                    // convert model file to glb
                    asset = await dispatch('api/modelsFromFileConvertPost', {
                        file: base64,
                        additional
                    }, { root: true })
                }

                // set the asset item
                await dispatch('boxurizer/setItemAsset', asset, { root: true })
            }
            catch(error)
            {
                throw error
            }
        },
        async setItemAsset({ getters, commit, dispatch, rootGetters }, asset)
        {
            try
            {
                // ensure asset parameter exists
                if (asset === null) {
                    throw new Error("No asset item provided.")
                }

                // set item model asset
                commit('SET_ITEM_ASSET', asset)

                // ensure default file is requested
                const fileDefault = await dispatch('api/assetsAssetFileKeyGet', {
                    asset
                }, { root: true })

                // load mesh
                loadMesh('gltf', fileDefault.data)
                .then((mesh) => {

                    // center geometry
                    const offset = centerGeometry(mesh.geometry)

                    // normalize geometry
                    const { width, height, depth } = normalizeGeometry(mesh.geometry, getters.keyConversionSize)

                    // commit item to store
                    commit('SET_ITEM_MESH', mesh)

                    commit('SET_ITEM_CENTER', { x: offset.x, y: offset.y, z: offset.z })
                    commit('SET_ITEM_SIZE_ORIGINAL', { width, height, depth })
                    commit('SET_ITEM_SIZE_CURRENT', { width, height, depth })

                    // reset camera perspective
                    dispatch('setCameraPerspectiveToDefault')

                })
            }
            catch(error)
            {
                throw error
            }
        },
        setItemVisible({ commit }, visible) {
            commit('SET_ITEM_VISIBLE', visible)
        },
        setItemSizeCurrent({ commit }, { width, height, depth }) {
            commit('SET_ITEM_SIZE_CURRENT', { width, height, depth })
        },
        setItemSizeCurrentConverted({ commit }, { width, height, depth }) {
            commit('SET_ITEM_SIZE_CURRENT_CONVERTED', { width, height, depth })
        },
        setItemDirection({ getters, commit }, direction) {
            commit('SET_ITEM_DIRECTION', direction)

            // 
            const mesh = getters.getItemMesh
            const euler = getters.getItemDirectionEuler

            mesh.rotation.set(
                euler[0] * (Math.PI / 180),
                euler[1] * (Math.PI / 180),
                euler[2] * (Math.PI / 180)
            )
        },
        async optimizeItem({ getters, commit, dispatch }) {     
            try
            {
                // call route to optimize item asset
                const response = await dispatch('api/modelsModelOptimizePost', {
                    asset: getters.getItemAsset,
                    reducing_active: true,
                    inflating_active: true
                }, { root: true })

                // wait for optimization to be complete
                const fileOptimize = await dispatch('api/assetsAssetFileKeyGet', {
                    asset: response,
                    key: 'optimize',
                    timeMaximum: 300000 // wait five minutes at max
                }, { root: true })

                // load mesh
                loadMesh('gltf', fileOptimize.data)
                .then((mesh) => {

                    // normalize geometry
                    normalizeGeometry(mesh.geometry, getters.keyConversionSize)

                    // commit item to store
                    commit('SET_ITEM_OPTIMIZED', mesh)
                })

                return fileOptimize
            }
            catch(error)
            {
                throw error
            }
        },
        setItemDebugging({ commit }, debugging) {
            commit('SET_ITEM_DEBUGGING', debugging)
        },

        clearContainer({ commit }) {
            commit('RESET_INFILL')
            commit('RESET_CONTAINER')
        },
        setContainerVisible({ commit }, visible) {
            commit('SET_CONTAINER_VISIBLE', visible)
        },
        setContainerColumns({ commit }, columns) {
            commit('SET_CONTAINER_COLUMNS', columns)
        },
        setContainerStacks({ commit }, stacks) {
            commit('SET_CONTAINER_STACKS', stacks)
        },
        setContainerRows({ commit }, rows) {
            commit('SET_CONTAINER_ROWS', rows)
        },
        setContainerSelection({ commit }, selection) {
            commit('SET_CONTAINER_SELECTION', selection)
        },
        setContainerOptions({ commit, dispatch }, options) {
            commit('SET_CONTAINER_OPTIONS', options)
            dispatch('updateAppAdditional')
        },
        /*
        createContainerOption({ commit }, { label, width, height, depth }) {
            const container = new Container(label, width, height, depth)
            commit('ADD_CONTAINER_OPTION', container)
            return container
        },
        removeContainerOption({ commit }, containerId) {
            commit('REMOVE_CONTAINER_OPTION', containerId)
        },
        */
        setContainerSpacingMode({ commit }, mode) {
            commit('SET_CONTAINER_SPACING_MODE', mode)
        },
        setContainerSpacingMode({ commit }, mode) {
            commit('SET_CONTAINER_SPACING_MODE', mode)
        },
        setContainerSpacingMinimum({ commit }, minimum) {
            commit('SET_CONTAINER_SPACING_MINIMUM', minimum)
        },
        setContainerSpacingFixed({ commit }, fixed) {
            commit('SET_CONTAINER_SPACING_FIXED', fixed)
        },
        setContainerSidesHighlight({ commit }, highlight) {
            commit('SET_CONTAINER_SIDES_HIGHLIGHT', highlight)
        },
        setContainerSidesSelection({ commit, getters }, selection) {

            /*
            deactivated automatic removing of infills from sides not selected

            const selectionKeys = Object.values(selection)
            const originalList = getters.getInfillList
            const filteredList = originalList.filter(infill => selectionKeys.includes(infill.side))
        
            if (originalList.length !== filteredList.length) 
            {
                commit('SET_INFILL_LIST', filteredList)
                commit('SET_INFILL_CALCULATED', false)
            }

            */

            commit('SET_CONTAINER_SIDES_SELECTION', selection)
        },

        clearInfill({ commit }) {
            commit('RESET_INFILL')
        },
        createInfill({ commit }, { side, position }) {
            const infill = new Infill(side, position) 
            commit('ADD_INFILL', infill)
            commit('SET_INFILL_CALCULATED', false)
            return infill
        },
        deleteInfill({ commit }, infillId) {
            if (infillId) 
            {
                commit('REMOVE_INFILL', infillId)
                commit('SET_INFILL_CALCULATED', false)
            }
        },
        deleteSelectedInfill({ commit, getters }) {
            const selection = getters.getInfillSelection
            if (selection) 
            {
                commit('REMOVE_INFILL', selection)
                commit('SET_INFILL_CALCULATED', false)
            }
        },
        setInfillVisible({ commit }, visible) {
            commit('SET_INFILL_VISIBLE', visible)
        },
        setInfillSelection({ commit }, selection) {
            commit('SET_INFILL_CALCULATED', false)
            commit('SET_INFILL_SELECTION', selection)
        },
        setInfillTolerance({ commit }, tolerance) {
            commit('SET_INFILL_TOLERANCE', tolerance)
            commit('SET_INFILL_CALCULATED', false)
        },
        setInfillOptimized({ commit }, optimized) {
            commit('SET_INFILL_OPTIMIZED', optimized)
            commit('SET_INFILL_CALCULATED', false)
        },
        setInfillAsset({ commit }, asset) {
            commit('SET_INFILL_ASSET', asset)
        },
        setInfillCalculated({ commit }, calculated) {
            commit('SET_INFILL_CALCULATED', calculated)
        },  
        async calculateInfill({ getters, commit, dispatch, rootGetters }) 
        {   
            // mark infill as not yet calculated
            commit('SET_INFILL_CALCULATED', false)

            try
            {
                // get script parameter
                const scale = getters.keyConversionSize
                const itemCenter = getters.isInfillOptimized ? [0, 0, 0] : getters.getItemCenter
                const itemSizeOriginal = getters.getItemSizeOriginal
                const itemSizeCurrent = getters.getItemSizeCurrent
                const itemTolerance = getters.getInfillTolerance / 100
                const itemScale = [
                    (itemSizeCurrent.width / itemSizeOriginal.width) + itemTolerance,
                    (itemSizeCurrent.height / itemSizeOriginal.height) + itemTolerance,
                    (itemSizeCurrent.depth / itemSizeOriginal.depth) + itemTolerance
                ]
                const itemDirection = getters.getItemDirectionEuler
                const itemAsset = getters.getItemAsset
                const itemAssetFileKey = getters.isInfillOptimized ? 'optimize' : 'default'
                const slots = getters.getContainerSlots
                const infillList = getters.getInfillList

                // script infills
                let scriptInfills = `module infills() {`
                for (const infill of infillList) 
                {
                    const attr = getters.getInfillAttributes(infill)
                    scriptInfills += `infill(${attr.width}, ${attr.height}, ${attr.depth},
                        [${attr.position.x}, ${attr.position.y}, ${attr.position.z}], 
                        [${attr.limit[0]}, ${attr.limit[1]}, ${attr.limit[2]}], 
                        ${attr.edge});`
                }
                scriptInfills += `}`

                // script item
                const scriptItem =
                `
                module item(posX, posY, posZ) 
                {
                    translate([posX / ${scale}, posY / ${scale}, posZ / ${scale}])
                    {
                        scale([${itemScale[0]}, ${itemScale[1]}, ${itemScale[2]}])
                        {
                            rotate([${itemDirection[0]}, ${itemDirection[1]}, ${itemDirection[2]}])
                            {
                                translate([${itemCenter[0]}, ${itemCenter[1]}, ${itemCenter[2]}])
                                {
                                    {{id:${itemAsset.id},key:'${itemAssetFileKey}'}}
                                }
                            }
                        }
                    }
                }
                `

                // script items
                let scriptItems = `module items() {`
                for (const slot of slots) 
                {
                    scriptItems += `item(${slot.x}, ${slot.y}, ${slot.z});`
                }
                scriptItems += `}`

                // general script 
                const script = 
                `
                    ${scriptInfills}
                    ${scriptItem}
                    ${scriptItems}

                    rotate([90, 0, 0])
                    {
                        difference()
                        {
                            infills();
                            items();
                        }
                    }
                `

                // specify model type (infill)
                const additional = { 
                    type: MODEL_TYPE_INFILL
                }

                // call route to generate model from script
                const asset = await dispatch('api/modelsFromScriptGeneratePost', {
                    script,
                    libraries: [ 'infill' ],
                    additional
                }, { root: true })

                // set infill asset
                await dispatch('setInfillAsset', { asset })

                // mark infill as calculated
                commit('SET_INFILL_CALCULATED', true)

                return asset
            }
            catch(error)
            {
                console.log(error)
                throw error
            }
        },
        async setInfillAsset({ commit, getters, dispatch }, {
            asset
        })
        {
            try
            {
                // ensure asset parameter exists
                if (asset === null) {
                    throw new Error("No asset item provided.")
                }

                // set new infill asset
                commit('SET_INFILL_ASSET', asset)

                // ensure default file is requested
                const fileDefault = await dispatch('api/assetsAssetFileKeyGet', {
                    asset,
                    timeMaximum: 300000 // wait five minutes at max
                }, { root: true })

                // load mesh
                loadMesh('gltf', fileDefault.data)
                .then((mesh) => {

                    // repair texture mapping (UVs)
                    mesh = repairTextureMapping(mesh, getters.keyConversionSize)

                    // fix normals
                    mesh.geometry.computeVertexNormals()

                    // commit infill to store
                    commit('SET_INFILL_MESH', mesh)
                })
            }
            catch(error) 
            {
                throw error
            }
        },
        async exportInfill({ commit, getters }) 
        {
            if (!getters.isInfillCalculated)
            {
                throw new Error('Infill needs to be calculated first.')
            }

            try
            {
                // get infill mesh
                const infillMesh = getters.getInfillMesh

                // get export scaling (100 means no scaling; value in percent)
                const exportScaling = getters.getExportScaling

                // get geometry and apply scaling
                const geometry = infillMesh.geometry.clone()
                const scaleFactor = exportScaling / 100
                geometry.scale(scaleFactor, scaleFactor, scaleFactor)

                // split geometry into unconnected geometries / make to individual meshes
                const geometries = divideGeometry(geometry)

                // download all meshes
                const exporter = new STLExporter()

                geometries.forEach((geom, index) => {
                    const mesh = new THREE.Mesh(geom, infillMesh.material)
                    const data = exporter.parse(mesh)

                    downloadFile(data, 'text/plain', 'infill_' + index, 'stl')
                })

                if (geometries.length >= 10)
                {
                    throw new Error('Export of infill failed.')
                }

                // TODO negative infill
            }
            catch(error)
            {
                throw error
            }
        },

        setRenderMode({ commit }, mode) {
            commit('SET_RENDER_MODE', mode)
        },
        setRenderModeToDefault({ commit }) {
            commit('SET_RENDER_MODE', RENDER_MODE_DEFAULT)
        },
        setRenderModeToTextured({ commit }) {
            commit('SET_RENDER_MODE', RENDER_MODE_TEXTURED)
        },
        setRenderModeToSolid({ commit }) {
            commit('SET_RENDER_MODE', RENDER_MODE_SOLID)
        },
        setRenderModeToWireframe({ commit }) {
            commit('SET_RENDER_MODE', RENDER_MODE_WIREFRAME)
        },
        setRenderFormat({ commit }, format) {
            commit('SET_RENDER_FORMAT', format)
        },

        setExportScaling({ commit }, scaling) {
            commit('SET_EXPORT_SCALING', scaling)
        },
        setExportType({ commit }, type) {
            commit('SET_EXPORT_TYPE', type)
        },
        setExportFormat({ commit }, format) {
            commit('SET_EXPORT_FORMAT', format)
        },
    }
}