//// FILE

// get extension from filename
export function extractExtension(filename)
{
    // split the filename into parts on the dot
    const parts = filename.split('.')

    // if only one part, nothing to extract
    if (parts.length == 1) return null

    // pop last item and make it lower case
    return parts.pop().toLowerCase()
}

// remove extension from filename string
export function removeExtension(filename) 
{
    // split the filename into parts on the dot
    const parts = filename.split('.')

    // if there's no dot or only one part, return the filename as is
    if (parts.length === 1 || (parts[0] === '' && parts.length === 2)) {
        return filename
    }

    // join the parts back together, excluding the last part (the extension)
    return parts.slice(0, -1).join('.')
}

export function base64ToBinary(data)
{
    // extract raw base64 string (in case of pre mime type)
    const base64 = data.split(',')[1] 

    // convert base64 to binary
    const binary = atob(base64)

    return binary
}

export function base64ToArrayBuffer(data)
{
    // create binary
    const binary = base64ToBinary(data)

    // create new array buffer
    const arrayBuffer = new ArrayBuffer(binary.length)

    // create unsigned int (8 bit) array
    const uint8Array = new Uint8Array(arrayBuffer)

    // iterate over all character
    for (let i = 0; i < binary.length; i++) {
        uint8Array[i] = binary.charCodeAt(i)
    }

    return arrayBuffer
}

// download file
export function downloadFile(data, type, name, extension)
{
    // create download link
    const blob = new Blob([data], { type })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = `generio_${name}.${extension}`

    // download
    link.click()

    // clean up
    URL.revokeObjectURL(link.href)
}